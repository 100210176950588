import React, { useContext, useEffect, useState } from "react";
import s from "./CreateProject.module.scss";
import RenderActionComponent from "../../../RenderActionComponent/RenderActionComponent";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "../../../Button/Button";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import infoImg from "./ImportFile/assets/info.svg";
import Products from "./Products/Products";
import Papers from "./Papers/Papers";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import FabricJSContextNew from "../../../../core/context/FabricJSContextNew";
import { useNavigate } from "react-router-dom";
import ProjectContext from "../../../../core/context/ProjectContext";
import { uniqueID, useNotification } from "../../../../core/context/notifications/NotificationProvider";
import FolderTreeStructure from "../../../FolderComponents/FolderTreeStructure/FolderTreeStructure";
import CreateModal from "../CreateModal";

const initialValues = {
    projectName: "",
};

interface TCreateProject {
    setCloseModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateProject = ({ setCloseModal }: TCreateProject) => {
    const { t } = useTranslation();
    const nav = [
        { link: "search", name: "Search" },
        { link: "favourite", name: "Favourite" },
        { link: "last_used", name: "LastUsed" },
        { link: "products", name: "Products" },
    ];
    const navigate = useNavigate();
    const notification = useNotification();
    const [choosenFolder, setChoosenFolder] = useState<number>(0);
    const { sendRequest, isLoading } = useApiRequest();

    const { setProjects } = useContext(ProjectContext);

    const {
        setActiveGlobalState,
        setTempActiveGlobalState,
        tempActiveGlobalState,
        setCreatingNewWork,
    } = useContext(FabricJSContextNew);

    const [actionState, setActionState] = useState<string>("products");
    const [inputs, setInputs] = useState<any>(initialValues);
    const [fileToSend, setFileToSend] = useState<any>(null); // Store file to be uploaded TODO: update functionality
    const [isExpanded, setIsExpanded] = useState(false); // state to track the "ShowAll" / "Show" toggle

    useEffect(() => {
        setInputs((prevInputs: any) => ({
            ...prevInputs,
            name: tempActiveGlobalState.name || "",
        }));
    }, [tempActiveGlobalState]);

    const inputHandler = (e: any) => {
        const { name, value } = e.target;
        setInputs((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));

        setTempActiveGlobalState((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const actions: Record<string, JSX.Element> = {
        search: <div>{t("Search")}</div>,
        favourite: <div>{t("Favourite")}</div>,
        last_used: <div>{t("LastUsed")}</div>,
        products: (
            <Products
                setTempActiveGlobalState={setTempActiveGlobalState}
                handleDoubleClick={() => handleCreateNewProject()}
                isExpanded={isExpanded}
            />
        ),
        paper: <Papers />,
    };

    // POST CREATE PROJECT
    const handleCreateNewProject = async () => {
        try {
            const response = await sendRequest("post", `creator/2d/projects`, {
                template_id: tempActiveGlobalState?.fullJSON?.id,
                name: inputs.projectName,
                category_id: choosenFolder,
            });

            if (response && response.data) {
                notification({
                    id: uniqueID,
                    type: "SUCCESS",
                    message: t("Create.ProjectDrafCreated"),
                });
                const responseData = response.data as any;

                setActiveGlobalState({
                    fullJSON: responseData,
                    canvasType: "projects",
                    selectedView: {
                        ...responseData.versions[0],
                        view_uuid: tempActiveGlobalState.selectedView.uuid,
                    },
                    generateThumbnail: null,
                });

                setProjects((prevProjects: any) => [...prevProjects, responseData]);
                setCreatingNewWork((prevState: any) => ({
                    ...prevState,
                    workType: "project",
                    new: true,
                }));

                navigate(`/design-studio/project/${responseData?.token}&is_draft=true`);
                if (setCloseModal) {
                    setCloseModal((prevState: boolean) => !prevState);
                }
            }
        } catch (err) {
            console.error(err);
        } finally {
        }
    };

    // Toggle for ShowAll/Show
    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div className={s.container}>
            {/* TODO: handle adding  */}
            <CreateModal
                type="project"
                setFileToSend={setFileToSend}
                onFileSelectedAndInvoke={() => console.log("File selected")}
            >
                <div className={s.main}>
                    <div className={s.nav}>
                        {nav.map((el, i: number) => (
                            <span
                                className={actionState === el.link ? s.active : undefined}
                                key={`Create-project-map-${i}`}
                                onClick={() => setActionState(el.link)}
                            >
                                {el.name === "Search" && (
                                    <SearchIcon className={s.search_icon} />
                                )}
                                {t(el.name)}
                            </span>
                        ))}
                        <span>
                            <a href="#" onClick={toggleExpand}>
                                {isExpanded ? t("Collapse") : t("ShowAll")}
                            </a>
                        </span>
                    </div>
                    <RenderActionComponent actions={actions} action={actionState} />
                </div>
            </CreateModal>
        </div>
    );
};

export default CreateProject;
