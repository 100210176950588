import React, { CSSProperties, useContext, useState, useRef } from "react";
import AddIconButton from "../../../../../components/AddIconButton/AddIconButton";
import { ReactComponent as ALetterIcon } from "../../../../../assets/svg/ALetterIcon.svg";
import { ReactComponent as PictureIcon } from "../../../../../assets/svg/PictureIcon.svg";
import { createGraphicHandler } from "../../../../../helper/createGraphicHandler";
import AuthContext from "../../../../../core/context/AuthContext";
import { useApiRequest } from "../../../../../core/api/useApiRequest";
import { useNotification } from "../../../../../core/context/notifications/NotificationProvider";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import addGateway from "../../../fabric-functions/AddToCanvas/AddGateway";
import { useTranslation } from "react-i18next";

const style: CSSProperties = {
  position: "absolute",
  bottom: "0",
  left: "calc(50% - 150px)",
  transform: "translate(-50%, -50%)",
  display: "flex",
  gap: "10px",
};

const AddPanel = () => {
  const [fileToUpload, setFileToUpload] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [addedImages, setAddedImages] = useState<any>([]);
  const [fetchGraphic, setFetchGraphics] = useState<boolean>(false);

  const { user } = useContext(AuthContext);
  const { sendRequest } = useApiRequest();
  const notification = useNotification();
  const { canvasContext, setCanvasObjectList, activeGlobalState } = useContext(FabricJSContextNew);
  const { t } = useTranslation();
  const levelBased = activeGlobalState?.canvasType === "templates" ? 1 : 4;

  // Create a ref for the hidden file input
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  // Trigger the hidden file input when user clicks "Add Graphic"
  const handleAddGraphicClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  // Forward file selection event to createGraphicHandler
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await createGraphicHandler({
      event: e,
      user,
      sendRequest,
      notification,
      canvasContext,
      setCanvasObjectList,
      levelBased,
      setFileToUpload,
      setImageUrl,
      setAddedImages,
      setFetchGraphics,
    });
  };
  const handleAddText = (text: string) => {
    const textData = {
      text,
      fontSize: 16,
      color: "#000000",
      fileType: "text",
      level: 4,
    };

    addGateway(canvasContext, textData, setCanvasObjectList);
  };
  return (
    <div style={style}>
      <AddIconButton handleClick={() => handleAddText(t("New Text"))} tooltip="Add Text" Icon={ALetterIcon} />

      {/* "Add Graphic" button triggers hidden file input */}
      <AddIconButton handleClick={handleAddGraphicClick} tooltip="Add Graphic" Icon={PictureIcon} />

      {/* Hidden file input */}
      <input type="file" ref={hiddenFileInput} style={{ display: "none" }} onChange={handleFileChange} />
    </div>
  );
};

export default AddPanel;
