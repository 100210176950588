import React, { forwardRef } from "react";
import s from "./SelectOption.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { iconMap } from "./options";

interface Option {
  name: string;
  link?: string;
  function?: () => void;
  icon?: string;
}

interface ISelectOption {
  optionGroups: Array<Option[]>;
  style?: React.CSSProperties;
}

const SelectOption = forwardRef<HTMLDivElement, ISelectOption>(
  ({ optionGroups, style }, ref) => {
    const navigate = useNavigate();

    const handleOptionClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      option: Option
    ) => {
      e.stopPropagation();

      if (!option.link) {
        e.preventDefault(); // Prevent navigation if no link is provided
      }

      if (option.function) {
        option.function();
      }

      if (option.link) {
        navigate(option.link);
      }
    };

    const renderLink = (el: Option, i: number) => {
      const Icon = el.icon ? iconMap[el.icon] : null;
      return (
        <div
          key={`Select-option-item-${el.name}-${i}`} // Unique key
          className={s.option_item}
          onClick={(e) => handleOptionClick(e, el)}
        >
          {Icon && <Icon className={s.icon} fontSize="medium" />}
          <Link to={el.link || "#"} draggable={false}>
            {el.name}
          </Link>
        </div>
      );
    };

    const renderGroup = (options: Option[], isLast: boolean) => {
      return (
        <>
          {options.map((el: Option, i: number) => renderLink(el, i))}
          {!isLast && <div className={`${s.solid_line} solid-line`}></div>}
        </>
      );
    };

    return (
      <div className={s.container} style={style} ref={ref} draggable={false}>
        {optionGroups.map((options: Option[], i: number) =>
          renderGroup(options, i === optionGroups.length - 1)
        )}
      </div>
    );
  }
);

export default SelectOption;
