import { useTranslation } from "react-i18next";
import s from "./SwitcherPaymentType.module.scss";
import { PaymentType } from "../../types/generalTypes";

interface ISwitcherPaymentType {
  type: PaymentType;
  setType: React.Dispatch<React.SetStateAction<PaymentType>>;
}

const SwitcherPaymentType = ({ type, setType }: ISwitcherPaymentType) => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <div className={`${s.monthly} ${type === "monthly" ? s.active : ""}`} onClick={() => setType("monthly")}>
        <span>{t("Monthly")}</span>
        <div>
          <span>30% </span>
          <span>{t("PlanSelectionComponent.Cheaper")}</span>
        </div>
      </div>
      <div className={`${s.yearly} ${type === "yearly" ? s.active : ""}`} onClick={() => setType("yearly")}>
        <span>{t("Yearly")}</span>
        <div>
          <span>50% </span>
          <span>{t("PlanSelectionComponent.Cheaper")}</span>
        </div>
      </div>
    </div>
  );
};

export default SwitcherPaymentType;
