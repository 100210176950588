const variantProps = {
  y: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  "flex-direction": "column",
  paddingTop: "8px",
  transition: {
    duration: 0.2,
    ease: "easeInOut",
  },
};

export const variantsNav = {
  enter: (custom: any) => ({
    ...variantProps,
    paddingTop: "0px",
    x: custom > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    ...variantProps,
    paddingTop: "0px",
    x: 0,
    opacity: 1,
  },
  exit: (custom: any) => ({
    ...variantProps,
    paddingTop: "0px",
    x: custom > 0 ? 300 : -300,
    opacity: 0,
  }),
};

export const variantsFolders = {
  enter: (custom: any) => ({
    ...variantProps,
    x: custom > 0 ? 300 : -300,
    opacity: 0,
    transition: {
      delay: 0.2,
      duration: 0.2,
      ease: "easeInOut",
    },
  }),
  center: {
    ...variantProps,
    x: 0,
    opacity: 1,
  },
  exit: (custom: any) => ({
    ...variantProps,
    x: custom > 0 ? 300 : -300,
    opacity: 0,
  }),
};
