import { useTranslation } from "react-i18next";
import s from "./CreateModal.module.scss";
import ImportFile from "./ImportFile/ImportFile";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../core/context/AuthContext";

interface CreateModalProps {
  type: "project" | "product" | "graphic";
  children?: React.ReactNode;
  setFileToSend: (file: any) => void;
  onFileSelectedAndInvoke?: (file: any) => void;
  style?: React.CSSProperties;
}

type TranslationType =
  | "CreateProject"
  | "CreateProduct"
  | "CreateProjectFree"
  | "AddGraphic";

interface ModalType {
  translation: TranslationType;
}

const CreateModal = ({
  children,
  type,
  setFileToSend,
  onFileSelectedAndInvoke,
  style,
}: CreateModalProps) => {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const [typeObject, setTypeObject] = useState("CreateProduct");

  useEffect(() => {
    console.log(type);
    let translation: TranslationType =
      type === "product"
        ? "CreateProduct"
        : type === "project"
        ? "CreateProject"
        : "AddGraphic";
    setTypeObject(translation);
  }, [type, user]);

  return (
    <div className={s.container}>
      <div className={s.header}>
        <h2>{t(typeObject + ".one")}</h2>
        <h4>{t(typeObject + ".two")}</h4>
      </div>
      <div className={s.content} style={style}>
        {type !== "graphic" ? (
          <>
            <div className={s.main}>{children}</div>
            <ImportFile
              setFileToSend={setFileToSend}
              onFileSelectedAndInvoke={onFileSelectedAndInvoke}
              // onFileSelectedAndInvoke={!user ? handleNotLoggedInFlow : undefined}  Only invoke automatically if not logged in
            />
          </>
        ) : (
          <div className={s.main}>
            <ImportFile
              setFileToSend={setFileToSend}
              onFileSelectedAndInvoke={onFileSelectedAndInvoke}
              isGraphic={true}
              // onFileSelectedAndInvoke={!user ? handleNotLoggedInFlow : undefined}  Only invoke automatically if not logged in
            />
          </div>
        )}
        {/*<div className={s.right}>*/}

        {/*</div>*/}
      </div>
    </div>
  );
};

export default CreateModal;
