import React, { useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import s from "./ProductLeftBar.module.scss";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@mui/material";
import { ReactComponent as SearchLoop } from "../../../../../assets/icons/loop.svg";

import Button from "../../../../Button/Button";
import SlideUpModal from "../../../../Modals/Modal/SildeUpModal";
import CreateProduct from "../../../../Modals/CreateProduct/CreateProduct";
import ProductContext from "../../../../../core/context/ProductContext";
import AuthContext from "../../../../../core/context/AuthContext";
import FolderTreeStructure from "../../../../FolderComponents/FolderTreeStructure/FolderTreeStructure";
import ExpandableFolderList from "../../../../FolderComponents/ExpandableFolderList/ExpandableFolderList";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import ProductComponent from "../../../../ProductComponent/ProductComponent";
import ContentLeftBar from "../components/ContentLeftBar/ContentLeftBar";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import { useNavigate } from "react-router-dom";
import { IFolder } from "../../../../../core/types/types";
import { ReactComponent as Design } from "../../../../../assets/svg/Design.svg";

// Adjust styles for your Button
const buttonStyles = {
  marginLeft: "auto",
  width: "100%",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: 700,
  color: "#575757",
  className: "add",
  justifyContent: "space-evenly",
};

const ProductsLeftBar = () => {
  const { setActiveGlobalState } = useContext(FabricJSContextNew);
  const [openProductCreate, setOpenProductCreate] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const correctPath = !!user ? "design-studio" : "free-editor";

  const handleCreateNewProduct = () => {
    if (!user) return;
    setOpenProductCreate(true);
  };

  const productHandler = (selectedView: any, wholeProduct: any) => {
    setActiveGlobalState({ canvasType: "templates", fullJSON: wholeProduct });
    navigate(`/${correctPath}/product/${wholeProduct?.token}`);
  };

  const [searchParams, setSearchParams] = useState<string>("");
  const [selectedFolder, setSelectedFolder] = useState<IFolder>({
    id: 0,
    parent_id: 0,
    name: "root",
    token: "",
  });

  return (
    <>
      <div className={s.container}>
        <Button
          text={t("New product")}
          type="button"
          styles={buttonStyles}
          Icon={Design}
          handleClick={handleCreateNewProduct}
          disabled={!user}
          isThreePiece={true}
        />

        <TextField
          label={t("Search")}
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "-10px" }}
          onChange={(e) => setSearchParams(e.target.value)}
          size="small"
          value={searchParams}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchLoop />
              </InputAdornment>
            ),
          }}
        />

        {/* Folder Tree Structure always visible, for example. */}
        <FolderTreeStructure
          requestPath="templates"
          setSelectedFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
        />

        <div className={s.list_container}>
          <span>{t("LastUsed")}</span>
          <FilterComponent
            endpoint="/creator/2d/templates"
            defaultSort="-id"
            defaultPagination={3}
            isPagination={false}
            defaultFilter={"is_draft:false"}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProductComponent
                    key={`dashboard-product-products-recent-map${i}`}
                    product={el}
                    variant="3ex_small"
                    handleClick={(variant) => productHandler(variant, el)}
                    componentOptions="2"
                    id={`dashboard-product-products-recent-map${i}`}
                  />
                ))}
              </div>
            )}
          />
        </div>

        <AnimatePresence>
          {searchParams.length === 0 && (
            <motion.div
              key="folder-content"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {selectedFolder.id === 0 ? (
                <ContentLeftBar
                  folderRequestPath="templates"
                  filterComponentRequestPath="templates"
                  setSelectedFolder={setSelectedFolder}
                />
              ) : (
                <div className={s.list_container}>
                  <span>{selectedFolder.name}</span>
                  <FilterComponent
                    endpoint="/creator/2d/templates"
                    defaultSort="id"
                    defaultPagination={10}
                    isPagination={true}
                    category_id={selectedFolder?.id.toString()}
                    render={({ data }) => (
                      <div className={s.map_container}>
                        {data?.map((el: any, i: number) => (
                          <ProductComponent
                            key={`dashboard-product-products-all-map${i}`}
                            product={el}
                            variant="3ex_small"
                            handleClick={(variant) => productHandler(variant, el)}
                            componentOptions="2"
                            id={`dashboard-product-products-all-map${i}`}
                          />
                        ))}
                      </div>
                    )}
                  />
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {searchParams.length > 0 && (
            <motion.div
              key="search-content"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{ marginTop: "1rem" }}
            >
              <FilterComponent
                endpoint="/creator/2d/templates"
                defaultSort="id"
                defaultPagination={10}
                isPagination={true}
                defaultFilter={`name:${searchParams}`}
                render={({ data }) => (
                  <div className={s.map_container}>
                    {data && data.length > 0 ? (
                      data.map((el: any, i: number) => (
                        <ProductComponent
                          key={`dashboard-product-products-all-map${i}`}
                          product={el}
                          variant="3ex_small"
                          handleClick={(variant) => productHandler(variant, el)}
                          componentOptions="2"
                          id={`dashboard-product-products-all-map${i}`}
                        />
                      ))
                    ) : (
                      <p className={s.no_results}>No Results Found</p>
                    )}
                  </div>
                )}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <SlideUpModal open={openProductCreate} onClose={() => setOpenProductCreate(false)}>
        <CreateProduct setCloseModal={setOpenProductCreate} />
      </SlideUpModal>
    </>
  );
};

export default ProductsLeftBar;
