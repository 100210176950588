import React, { useContext, useState } from "react";
import s from "./AddGraphic.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FabricJSContextNew from "../../../../core/context/FabricJSContextNew";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import {
  uniqueID,
  useNotification,
} from "../../../../core/context/notifications/NotificationProvider";
import AuthContext from "../../../../core/context/AuthContext";

import CreateModal from "../CreateModal";
import AppContext from "../../../../core/context/AppContext";

interface IAddGraphic {
  setOpen: any;
}

const contentStyle = { flex: "1" };

const AddGraphic = ({ setOpen }: IAddGraphic) => {
  const notification = useNotification();
  const { sendRequest } = useApiRequest();
  const [fileToSend, setFileToSend] = useState<any>(null); // Store file to be uploaded

  const { setForceCollectionRefetch } = useContext(AppContext);

  const { user } = useContext(AuthContext);
  const sendFileHandler = async (fileData: any) => {
    if (!user) {
      // If user is not logged in, you might just return a base64 approach or skip uploading
      return null;
    }

    const formData = new FormData();
    formData.append("attachment", fileData.file);
    formData.append("draft_id", fileData.draft_id);

    try {
      const response: any = await sendRequest(
        "post",
        "/upload-pending-attachment",
        formData
      );
      if (!response) {
        notification({
          id: uniqueID,
          type: "ERROR",
          message: "UPS something went wrong",
        });
        return null;
      }

      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Graphic has been uploaded",
      });
      setForceCollectionRefetch((prev) => ({
        refetch: !prev.refetch,
        type: "graphic",
      }));
      return response;
    } catch (err) {
      console.error(err);
      notification({
        id: uniqueID,
        type: "ERROR",
        message: "UPS something went wrong",
      });
      return null;
    } finally {
      setOpen(false);
    }
  };

  return (
    <div className={s.container}>
      <CreateModal
        type={"graphic"}
        setFileToSend={setFileToSend}
        style={contentStyle}
        onFileSelectedAndInvoke={sendFileHandler}
      ></CreateModal>
    </div>
  );
};

export default AddGraphic;
