import { useContext } from "react";
import s from "./ProductSearchList.module.scss";

import ProductComponent from "../../../../../components/ProductComponent/ProductComponent";
import DashboardTopHeader from "../../../../../components/DashboardTopHeader/DashboardTopHeader";
import { useNavigate } from "react-router-dom";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import { useTranslation } from "react-i18next";
import FilterComponent from "../../../../../components/FilterComponent/FilterComponent";
import SearchContext from "../../../../../core/context/SearchContext";
const ProductListSearch = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveGlobalState } = useContext(FabricJSContextNew);
  const { searchInput } = useContext(SearchContext);

  const productHandler = (selectedView: any, fullJSON: any) => {
    setActiveGlobalState((prevState: any) => ({ ...prevState, canvasType: "templates", fullJSON: fullJSON }));
    navigate(`/design-studio/template/${fullJSON.token}`);
  };

  return (
    <>
      {searchInput.trim().length > 0 && (
        <div className={s.container}>
          <DashboardTopHeader header={t("Products")} navigateTo={"/products"} />
          <div className={s.list_container}>
            <FilterComponent
              endpoint="/creator/2d/templates"
              defaultSort="id"
              defaultPagination={30}
              defaultSearch={searchInput}
              isSearch={true}
              isPagination={false}
              defaultFilter={"is_draft:false"}
              render={({ data, onDelete }) => (
                <div className={s.map_container}>
                  {data?.map((el: any, i: number) => (
                    <ProductComponent
                      key={`dashboard-product-products-recent-map${i}`}
                      product={el}
                      variant="medium"
                      handleClick={(variant) => productHandler(variant, el)}
                      componentOptions="1"
                      id={`dashboard-product-products-recent-map${i}`}
                      onDelete={onDelete}
                    />
                  ))}
                </div>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductListSearch;
