import React, { useContext, useEffect, useState } from "react";
import s from "./ProductsDashboard.module.scss";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import ProductComponent from "../../components/ProductComponent/ProductComponent";
import FabricJSContextNew from "../../core/context/FabricJSContextNew";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddIconButton from "../../components/AddIconButton/AddIconButton";
import { useTranslation } from "react-i18next";
import NormalModal from "../../components/Modals/Modal/NormalModal";
import ModalWrapper from "../../components/Modals/Modal.wrapper";
import CreateFolder from "../../components/Modals/FolderModals/CreateFolder/CreateFolder";
import AppContext from "../../core/context/AppContext";
import CreateProduct from "../../components/Modals/CreateProduct/CreateProduct";
import SlideUpModal from "../../components/Modals/Modal/SildeUpModal";
import FilterComponent from "../../components/FilterComponent/FilterComponent";
import handleLoadCanvasProject from "../Fabric-JS/fabric-functions/LoadProject";
import { ReactComponent as AddFolderIcon } from "../../assets/svg/AddFolderIcon.svg";

import FolderNodeTree from "../../components/FolderComponents/FolderNodeThree/FolderNodeTree";
import { Draggable, Droppable } from "react-beautiful-dnd";

const ProductsDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { folderToken } = useParams();

  const { folderPath, setFolderPath, currentFolder, isBeforeCapture } = useContext(AppContext);
  const { canvasContext, setActiveGlobalState } = useContext(FabricJSContextNew);

  const [openCreateFolder, setOpenCreateFolder] = useState<boolean>(false);
  const [openCreateProduct, setOpenCreateProduct] = useState<boolean>(false);

  const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);

  const projectHandler = (selectedView: any, wholeProduct: any) => {
    handleLoadCanvasProject(canvasContext, selectedView);
    setActiveGlobalState({ canvasType: "templates", fullJSON: wholeProduct });
    navigate(`/design-studio/template/${wholeProduct?.token}`);
  };

  useEffect(() => {
    if (folderPath.length > 0) {
      const lastFolderInArray: number = folderPath[folderPath.length - 1]?.id;

      setCurrentFolderId(lastFolderInArray);
    } else {
      setCurrentFolderId(null);
    }
  }, [folderPath]);

  const splitLocation = location.pathname.split("/")[1];
  return (
    <>
      <div className={s.container}>
        <h3>{t("Products")}</h3>

        <h4>{t("LastUsed")}</h4>
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/creator/2d/templates"
            defaultSort="id"
            defaultPagination={5}
            isPagination={false}
            render={({ data, onDelete }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProductComponent
                    key={`dashboard-product-products-recent-map${i}`}
                    product={el}
                    variant="medium"
                    handleClick={(variant) => projectHandler(variant, el)}
                    componentOptions="1"
                    id={`dashboard-product-products-recent-map${i}`}
                    onDelete={onDelete}
                  />
                ))}
              </div>
            )}
          />
        </div>

        <h4>{currentFolder ? currentFolder.name : t("All")}</h4>
        <div className={s.folder_div}>
          <FolderNodeTree
            requestPath="templates"
            routerPath="templates"
            folderPath={folderPath}
            setFolderPath={setFolderPath}
            variant="medium"
          />
        </div>
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/creator/2d/templates"
            defaultSort="id"
            defaultPagination={20}
            defaultFilter={"is_draft:false"}
            isPagination={true}
            category_id={currentFolderId?.toString()}
            render={({ data, onDelete }) => (
              <Droppable droppableId="product-list" type={splitLocation} isCombineEnabled={false} isDropDisabled={true}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <div className={s.map_container}>
                      {data?.map((el: any, i: number) => (
                        <Draggable
                          key={`dashboard-product-products-all-map-${el.id}`}
                          draggableId={`product-${el.id}`}
                          index={i}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  transform: snapshot.isDragging
                                    ? `${provided.draggableProps.style?.transform} scale(0.2)`
                                    : provided.draggableProps.style?.transform,
                                  height: snapshot.isDragging ? "15px" : "auto",
                                  width: snapshot.isDragging ? "15px" : "auto",

                                  transition: "transform 0.1s ease, height 0.1s ease, width 0.1s ease",
                                }}
                              >
                                <ProductComponent
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  product={el}
                                  variant="medium"
                                  handleClick={(variant) => projectHandler(variant, el)}
                                  componentOptions="1"
                                  id={`dashboard-product-products-all-map-${el.id}`}
                                  onDelete={onDelete}
                                  isDragging={isBeforeCapture}
                                  isDnD={true}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                    </div>
                    <div style={{ display: "none" }}>{provided.placeholder}</div>
                  </div>
                )}
              </Droppable>
            )}
          />
        </div>
      </div>
      <div className={s.button_div}>
        <AddIconButton handleClick={() => setOpenCreateProduct(true)} Icon={NoteAddIcon} tooltip={t("New product")} />
        <AddIconButton handleClick={() => setOpenCreateFolder(true)} Icon={AddFolderIcon} tooltip={t("New folder")} />
      </div>

      <SlideUpModal open={openCreateProduct} onClose={() => setOpenCreateProduct(false)}>
        <CreateProduct />
      </SlideUpModal>
      <NormalModal open={openCreateFolder} setOpen={setOpenCreateFolder}>
        <ModalWrapper setOpen={setOpenCreateFolder}>
          <CreateFolder
            setCloseModal={setOpenCreateFolder}
            parent_id={currentFolderId || 0}
            postCreateFolderPath="templates"
          />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default ProductsDashboard;
