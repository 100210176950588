import React from "react";
import s from "./AddIconButton.module.scss";
import { Tooltip } from "@mui/material";

interface IAddIconButton {
  handleClick: () => void;
  Icon: React.ElementType;
  tooltip: string;
}

const AddIconButton = ({ handleClick, Icon, tooltip }: IAddIconButton) => {
  return (
    <Tooltip title={tooltip}>
      <div className={s.container} onClick={() => handleClick()}>
        <Icon fontSize="large" />
      </div>
    </Tooltip>
  );
};

export default AddIconButton;
