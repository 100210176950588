import React, { useContext, useEffect, useState } from "react";
import s from "../WorkShopProduct.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import ImageIcon from "@mui/icons-material/Image";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import duplicateCanvasObject from "../../../../../fabric-functions/ManipulateObjects/DuplicateObject";
import {
  reorderCanvasObjects,
  updateCanvasObjectsList,
} from "../../../../../fabric-functions/UpdateCanvasObjectList";
import changeObjectName from "../../../../../fabric-functions/ManipulateObjects/ChangeObjectName";
import hideObject from "../../../../../fabric-functions/ManipulateObjects/HideObject";
import lockObject from "../../../../../fabric-functions/LockObject";
import deleteObject from "../../../../../fabric-functions/ManipulateObjects/DeleteObject";
const RenderLayers = () => {
  const { canvasContext, canvasObjectList, setCanvasObjectList }: any =
    useContext(FabricJSContextNew);

  const [columns, setColumns] = useState<any>({
    bottomLayers: {
      id: "bottomLayers",
      items: canvasObjectList.filter((obj: any) => obj.level === 1),
    },
    middleLayers: {
      id: "middleLayers",
      items: canvasObjectList.filter((obj: any) => obj.level === 4),
    },
    topLayers: {
      id: "topLayers",
      items: canvasObjectList.filter((obj: any) => obj.level === 5),
    },
  });

  useEffect(() => {
    setColumns({
      bottomLayers: {
        id: "bottomLayers",
        items: canvasObjectList.filter((obj: any) => obj.level === 1),
      },
      middleLayers: {
        id: "middleLayers",
        items: canvasObjectList.filter((obj: any) => obj.level === 4),
      },
      topLayers: {
        id: "topLayers",
        items: canvasObjectList.filter((obj: any) => obj.level === 5),
      },
    });
  }, [canvasObjectList]);

  const onDragEnd = async (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceItems = [...sourceColumn.items];
    const destItems =
      source.droppableId === destination.droppableId ? sourceItems : [...destColumn.items];

    const [removed] = sourceItems.splice(source.index, 1);

    const wasInMiddleLayers = source.droppableId === "middleLayers";

    if (source.droppableId !== destination.droppableId) {
      // Update the level based on the destination column
      if (destination.droppableId === "bottomLayers") {
        removed.level = 1;
      } else if (destination.droppableId === "middleLayers") {
        removed.level = 4;
      } else if (destination.droppableId === "topLayers") {
        removed.level = 5;
      }
    }

    destItems.splice(destination.index, 0, removed);

    const newColumns = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    };

    // Update 'order' property
    const updateOrder = (list: any) => {
      list.forEach((item: any, index: any) => {
        item.order = index;
      });
    };
    updateOrder(newColumns.bottomLayers.items);
    updateOrder(newColumns.middleLayers.items);
    updateOrder(newColumns.topLayers.items);

    // Update the fabric.js objects
    if (canvasContext) {
      const allObjects = canvasContext.getObjects();

      // Update levels and orders based on newColumns
      newColumns.bottomLayers.items.forEach((item: any) => {
        const obj = allObjects.find((o: any) => o.id === item.id);
        if (obj) {
          obj.level = 1;
          obj.order = item.order;

          // If object was originally in middle layers and now moved to bottom/top, remove clipping
          if (wasInMiddleLayers) {
            obj.clipPath = null;
            obj.dirty = true;
          }

          // Remove followers for objects moved out of level 4
          const followerId = `${obj.id}-clone`;
          const followerObj = allObjects.find((o: any) => o.id === followerId);
          if (followerObj) {
            canvasContext.remove(followerObj);
          }
        }
      });

      newColumns.middleLayers.items.forEach((item: any) => {
        const obj = allObjects.find((o: any) => o.id === item.id);
        if (obj) {
          obj.level = 4;
          obj.order = item.order;
        }
      });

      newColumns.topLayers.items.forEach((item: any) => {
        const obj = allObjects.find((o: any) => o.id === item.id);
        if (obj) {
          obj.level = 5;
          obj.order = item.order;

          // If object was originally in middle layers and now moved to bottom/top, remove clipping
          if (wasInMiddleLayers) {
            obj.clipPath = null;
            obj.dirty = true;
          }

          // Remove followers for objects moved out of level 4
          const followerId = `${obj.id}-clone`;
          const followerObj = allObjects.find((o: any) => o.id === followerId);
          if (followerObj) {
            canvasContext.remove(followerObj);
          }
        }
      });

      // Handle follower creation
      const createFollower = (object: any, cloneSettings: any): Promise<any> => {
        return new Promise((resolve) => {
          object.clone((clonedObject: any) => {
            clonedObject.set({
              id: cloneSettings.id,
              name: cloneSettings.name,
              level: cloneSettings.level,
              opacity: 0.5,
              selectable: true,
              evented: false,
              clipPath: undefined,
              isClone: true,
            });
            resolve(clonedObject);
          });
        });
      };

      const level4Objects = allObjects.filter((obj: any) => obj.level === 4);
      for (const level4Obj of level4Objects) {
        if (!level4Obj.id) continue;

        const followerId = `${level4Obj.id}-clone`;
        const hasFollower = allObjects.some((o: any) => o.id === followerId && o.level === 3);

        if (!hasFollower) {
          const cloneSettings = {
            id: `${level4Obj.id}-clone`,
            name: `${level4Obj.name ? level4Obj.name : level4Obj.id}-clone`,
            level: 3,
          };

          const clonedFollowerObj = await createFollower(level4Obj, cloneSettings);

          if (clonedFollowerObj) {
            clonedFollowerObj.set({
              left: level4Obj.left,
              top: level4Obj.top,
              originX: level4Obj.originX,
              originY: level4Obj.originY,
            });

            canvasContext.add(clonedFollowerObj);
            reorderCanvasObjects(canvasContext, allObjects);
            updateCanvasObjectsList(canvasContext, setCanvasObjectList);
          }
        }
      }

      // Reorder canvas objects
      const toShowAllObjects = allObjects
        .map((obj: any) => ({
          name: obj.name,
          type: obj.type,
          level: obj.level,
          order: obj.order,
        }))
        .sort((a: any, b: any) => {
          if (a.level !== b.level) {
            return a.level - b.level;
          }
          return a.order - b.order;
        });
      console.log(toShowAllObjects, "toShowAllObjects");

      // Re-render canvas after modifications
      canvasContext.renderAll();
    }

    setColumns(newColumns);
  };

  const handleObjectNameChange = (e: any, objectId: string) => {
    changeObjectName(canvasContext, objectId, e.target.value, setCanvasObjectList);
  };

  const handleToggleHideObject = (objectId: string) => {
    hideObject(canvasContext, objectId, setCanvasObjectList);
  };

  const handleToggleLockObjectById = (objectId: string) => {
    lockObject(canvasContext, objectId, setCanvasObjectList);
  };

  const handleDeleteObject = (selectedObjId: string) => {
    deleteObject(canvasContext, selectedObjId, setCanvasObjectList);
  };
  useEffect(() => {
    const preventDocumentScroll = (event: any) => event.preventDefault();

    window.addEventListener("scroll", preventDocumentScroll);

    return () => {
      window.removeEventListener("scroll", preventDocumentScroll);
    };
  }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {Object.entries(columns).map(([columnId, column]: any) => {
        let columnTitle = "";
        if (columnId === "bottomLayers") {
          columnTitle = "Bottom Layers";
        } else if (columnId === "middleLayers") {
          columnTitle = "Middle Layers";
        } else if (columnId === "topLayers") {
          columnTitle = "Top Layers";
        }
        return (
          <div key={`${columnId} + render plaers product workshop`}>
            <h3>{columnTitle}</h3>
            <Droppable
              droppableId={columnId}
              isDropDisabled={false}
              isCombineEnabled={false}
              ignoreContainerClipping={false}
            >
              {(provided: any) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "35px",
                  }}
                >
                  {column.items.map((el: any, index: number) => (
                    <Draggable key={`dasdk ${el.id}`} draggableId={el.id} index={index}>
                      {(provided: any) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                          className={s.layer_container}
                        >
                          {el?.id?.includes("-img") ? (
                            el?.level === 4 ? (
                              <Tooltip
                                title={el?.isDpiSufficient ? "Sufficient DPI" : "Insufficient DPI"}
                                arrow // Optional: Adds an arrow to the tooltip
                              >
                                <ImageIcon
                                  style={{
                                    color: el?.isDpiSufficient ? "#28a745" : "red",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <ImageIcon />
                            )
                          ) : (
                            <FormatBoldIcon />
                          )}

                          <input
                            type="text"
                            placeholder={el?.type.includes("text") ? el.text : el.name}
                            value={el?.type.includes("text") ? el.text : el.name}
                            onChange={(e) => handleObjectNameChange(e, el.id)}
                          />

                          <div className={s.icons}>
                            <Tooltip placement="top" title="Hide">
                              <VisibilityIcon onClick={() => handleToggleHideObject(el.id)} />
                            </Tooltip>
                            <Tooltip placement="top" title="Duplicate">
                              <ContentCopyIcon
                                onClick={() =>
                                  duplicateCanvasObject(
                                    canvasContext,
                                    el.id,
                                    setCanvasObjectList,
                                    el.level
                                  )
                                }
                              />
                            </Tooltip>
                            {el.hasControls ? (
                              <Tooltip placement="top" title="Lock">
                                <LockIcon onClick={() => handleToggleLockObjectById(el.id)} />
                              </Tooltip>
                            ) : (
                              <Tooltip placement="top" title="Unlock">
                                <LockOpenIcon onClick={() => handleToggleLockObjectById(el.id)} />
                              </Tooltip>
                            )}
                            <Tooltip placement="top" title="Delete">
                              <DeleteIcon
                                className="bin-icon-mui"
                                onClick={() => handleDeleteObject(el.id)}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        );
      })}
    </DragDropContext>
  );
};

export default RenderLayers;
