import React, { useContext, useState } from "react";
import SlideUpModal from "../../../../Modals/Modal/SildeUpModal";
import CreateProject from "../../../../Modals/CreateProject/CreateProject";
import s from "./ProjectLeftBar.module.scss";
import ProjectComponent from "../../../../ProjectComponent.tsx/ProjectComponent";
import { AnimatePresence, motion } from "framer-motion";

import Button from "../../../../Button/Button";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../core/context/AuthContext";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import { useNavigate } from "react-router-dom";
import handleLoadCanvasProject from "../../../../../features/Fabric-JS/fabric-functions/LoadProject";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import { InputAdornment, TextField } from "@mui/material";
import { ReactComponent as Design } from "../../../../../assets/svg/Design.svg";
import { ReactComponent as SearchLoop } from "../../../../../assets/icons/loop.svg";
import FolderTreeStructure from "../../../../FolderComponents/FolderTreeStructure/FolderTreeStructure";
import ContentLeftBar from "../components/ContentLeftBar/ContentLeftBar";
import { IFolder } from "../../../../../core/types/types";
const buttonStyles = {
  marginLeft: "auto",
  width: "100%",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: 700,
  color: "#575757",
  className: "add",
  justifyContent: "space-evenly",
};
const ProjectLeftBar = () => {
  const [openProjectCreate, setOpenProjectCreate] = useState<boolean>(false);
  const { setActiveGlobalState, canvasContext } = useContext(FabricJSContextNew);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const correctPath = !!user ? "design-studio" : "free-editor";

  const projectHandler = (selectedView: any, wholeProduct: any) => {
    handleLoadCanvasProject(canvasContext, selectedView);
    setActiveGlobalState({ canvasType: "templates", fullJSON: wholeProduct });
    navigate(`/design-studio/template/${wholeProduct?.token}`);
  };
  const [searchParams, setSearchParams] = useState<string>("");

  const [selectedFolder, setSelectedFolder] = useState<IFolder>({
    id: 0,
    parent_id: 0,
    name: "root",
    token: "",
  });
  return (
    <>
      <div className={s.container}>
        <Button
          text={"Nowy produkt"}
          type="button"
          styles={buttonStyles}
          Icon={Design}
          handleClick={() => setOpenProjectCreate(!openProjectCreate)}
          disabled={!user}
          isThreePiece={true}
        />
        <TextField
          label={t("Search")}
          id="outlined-basic"
          variant="outlined"
          sx={{ marginBottom: "-10px" }}
          onChange={(e) => setSearchParams(e.target.value)}
          fullWidth
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchLoop />
              </InputAdornment>
            ),
          }}
        />
        <FolderTreeStructure
          requestPath="projects"
          setSelectedFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
        />

        <div className={s.list_container}>
          <span>{t("LastUsed")}</span>
          <FilterComponent
            endpoint="/creator/2d/projects"
            defaultSort="-id" // Sort in descending order by ID to get the most recent items
            defaultPagination={3} // Limit to 3 items per request
            isPagination={false} // Disable pagination UI if not needed
            defaultFilter={"is_draft:false"}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProjectComponent
                    key={`left-bar-project-project-recent-map${i}`}
                    project={el}
                    variant="3ex_small"
                    handleClick={(variant) => projectHandler(variant, el)}
                    componentOptions="2"
                    id={`left-bar-project-project-recent-map${i}`}
                  />
                ))}
              </div>
            )}
          />
        </div>
        <AnimatePresence>
          {searchParams.length === 0 && (
            <motion.div
              key="folder-content"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {selectedFolder.id === 0 ? (
                <ContentLeftBar
                  folderRequestPath="projects"
                  filterComponentRequestPath="projects"
                  setSelectedFolder={setSelectedFolder}
                />
              ) : (
                <div className={s.list_container}>
                  <span>{selectedFolder.name}</span>
                  <FilterComponent
                    endpoint="/creator/2d/projects"
                    defaultSort="id"
                    defaultPagination={10}
                    isPagination={true}
                    defaultFilter={"is_draft:false"}
                    category_id={selectedFolder?.id.toString()}
                    render={({ data }) => (
                      <div className={s.map_container}>
                        {data?.map((el: any, i: number) => (
                          <ProjectComponent
                            key={`left-bar-projects-project-all-map${i}`}
                            project={el}
                            variant="3ex_small"
                            handleClick={(variant) => projectHandler(variant, el)}
                            componentOptions="2"
                            id={`left-bar-projects-project-all-map${i}`}
                          />
                        ))}
                      </div>
                    )}
                  />
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {searchParams.length > 0 && (
            <motion.div
              key="search-content"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{ marginTop: "1rem" }}
            >
              <FilterComponent
                endpoint="/creator/2d/projects"
                defaultSort="id"
                defaultPagination={10}
                isPagination={true}
                defaultFilter={`name:${searchParams}`}
                render={({ data }) => (
                  <div className={s.map_container}>
                    {data && data.length > 0 ? (
                      data.map((el: any, i: number) => (
                        <ProjectComponent
                          key={`dashboard-product-products-all-map${i}`}
                          project={el}
                          variant="3ex_small"
                          handleClick={(variant) => projectHandler(variant, el)}
                          componentOptions="2"
                          id={`dashboard-product-products-all-map${i}`}
                        />
                      ))
                    ) : (
                      <p className={s.no_results}>No Results Found</p>
                    )}
                  </div>
                )}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <SlideUpModal open={openProjectCreate} onClose={() => setOpenProjectCreate(false)}>
        <CreateProject setCloseModal={setOpenProjectCreate} />
      </SlideUpModal>
    </>
  );
};

export default ProjectLeftBar;
