import ModalWrapper from "../Modal.wrapper";
import NormalModal from "../Modal/NormalModal";
import ShareItem from "../ShareItem/ShareItem";

interface IShareItemModal {
  open: boolean;
  setOpen: any;
  isEditable?: boolean;
}

const ShareItemModal = ({
  open,
  setOpen,
  isEditable = true,
}: IShareItemModal) => {
  return (
    <NormalModal open={open} setOpen={setOpen}>
      <ModalWrapper setOpen={setOpen}>
        <ShareItem isEditable={isEditable} />
      </ModalWrapper>
    </NormalModal>
  );
};

export default ShareItemModal;
