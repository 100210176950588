import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./ProjectComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import SwiperCore from "swiper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../core/api/useApiRequest";
import SelectOptionWrapper from "../SelectOption/SelectOptionWrapper";
import ProjectContext from "../../core/context/ProjectContext";
import {
  uniqueID,
  useNotification,
} from "../../core/context/notifications/NotificationProvider";
import noImageAvailable from "../../assets/images/images.png";
import BackHandIcon from "@mui/icons-material/BackHand";
import AppContext from "../../core/context/AppContext";
import NormalModal from "../Modals/Modal/NormalModal";
import ModalWrapper from "../Modals/Modal.wrapper";
import ChangeName from "../Modals/ChangeName/ChangeName";
import ShareItemModal from "../Modals/ShareItemModal/ShareItemModal";

interface Variant {
  img_preview: string;
  name: string;
  size: string;
}
const noBacgroundComponent = {
  background: "transparent",
};
interface IprojectComponent {
  project: any;
  handleClick: (variant: Variant | null) => void;
  variant:
    | "3ex_small"
    | "2ex_small"
    | "ex_small"
    | "small"
    | "medium"
    | "large";
  id?: string;
  componentOptions: string;
  onDelete?: (id: number) => void; // Add onDelete prop
  isDragging?: boolean;
  isDnD?: boolean;
}
const selectStyle: React.CSSProperties = {
  position: "absolute",
  top: " 0",
  right: "10px",
  zIndex: "2",
};
const ProjectComponent: React.FC<IprojectComponent> = ({
  project,
  handleClick,
  variant,
  id,
  componentOptions,
  onDelete,
  isDragging,
  isDnD,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const { setForceCollectionRefetch } = useContext(AppContext);
  const [openMore, setOpenMore] = useState<boolean>(false);
  const [openChangeNameModal, setOpenChangeNameModal] =
    useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const { t } = useTranslation();
  const { sendRequest } = useApiRequest();

  const notification = useNotification();

  const deleteProject = async () => {
    try {
      const response = await sendRequest(
        "delete",
        `/creator/2d/projects/${project.id}`
      );
      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Project Deleted",
      });
      if (onDelete) {
        onDelete(project.id);
        setForceCollectionRefetch((prev) => ({
          refetch: !prev.refetch,
          type: "project",
        }));
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setOpenMore((prevState) => !prevState);
    }
  };

  const changeName = async () => {
    setOpenChangeNameModal((prevState) => !prevState);
  };

  const options = [
    [
      {
        icon: "open",
        function: handleClick,
        name: t("Open"),
      },
    ],
    [
      {
        icon: "changeName",
        function: changeName,
        name: t("Change name"),
      },
      {
        icon: "download",
        function: null,
        name: t("Download preview"),
      },
      {
        icon: "download",
        function: null,
        name: t("Download for print"),
      },
    ],
    [
      {
        icon: "share",
        function: () => setOpenShareModal(true),
        name: t("Share"),
      },
      {
        icon: "favourite",
        function: null,
        name: t("Add to favourite"),
      },
    ],
    [
      {
        icon: "delete",
        function: deleteProject,
        name: t("Delete"),
      },
    ],
  ];
  // console.log({ project });
  const handleSelectProject = (selectedView: any, event: any) => {
    event.stopPropagation();
    // console.log(selectedView, "selectedView");
    handleClick(selectedView);
  };

  const goNext = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    swiperRef.current?.slideNext();
  };

  const goPrev = (event: React.MouseEvent<SVGSVGElement>) => {
    event?.stopPropagation();
    swiperRef?.current?.slidePrev();
  };

  const handleSlideChange = () => {};
  return (
    <>
      <div
        className={`${s.container} ${
          variant === "2ex_small"
            ? s.two_ex_small
            : variant === "ex_small"
            ? s.ex_small
            : variant === "small"
            ? s.small
            : variant === "medium"
            ? s.medium
            : variant === "3ex_small"
            ? s.three_ex_small
            : s.large
        } ${isDragging ? s.dragging : ""} `}
      >
        <ComponentBackground
          id={id}
          styles={variant === "3ex_small" ? noBacgroundComponent : ""}
        >
          <div
            className={`${s.img_container} ${
              variant === "2ex_small"
                ? s.two_ex_small
                : variant === "ex_small"
                ? s.ex_small
                : variant === "small"
                ? s.small
                : variant === "medium"
                ? s.medium
                : variant === "3ex_small"
                ? s.three_ex_small
                : s.large
            }`}
          >
            <Swiper
              modules={[Navigation]}
              className="mySwiper"
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              onSlideChange={handleSlideChange} // Handle slide changes
              allowTouchMove={false} // Disable Swiper dragging
              simulateTouch={false}
            >
              {project?.versions?.map((el: any, i: any) => (
                <SwiperSlide
                  className={s.swiper_slide}
                  key={`dashboard-project-variant-${i}`}
                  // onClick={(e) => handleSelectProject(project.template.views[i], e)}                // onClick={(e) => handleSelectProject(project.template.views[i], e)}
                  onClick={(e) => handleSelectProject(el, e)}
                >
                  <img
                    src={el?.thumbnail ? el.thumbnail : noImageAvailable}
                    alt={el?.name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {project?.versions?.length > 1 ? (
              <>
                <ChevronLeftIcon
                  className={s.l}
                  fontSize="large"
                  onClick={goPrev}
                />
                <ChevronRightIcon
                  className={s.r}
                  fontSize="large"
                  onClick={goNext}
                />
              </>
            ) : null}
          </div>
        </ComponentBackground>
        {componentOptions === "1" ? (
          <SelectOptionWrapper
            options={options}
            openMore={openMore}
            setOpenMore={setOpenMore}
            style={selectStyle}
          />
        ) : null}
        {componentOptions === "1" ? (
          <div className={s.info}>
            <span>{project?.name}</span>
            <span></span>
          </div>
        ) : null}
      </div>
      <NormalModal open={openChangeNameModal} setOpen={setOpenChangeNameModal}>
        <ModalWrapper setOpen={setOpenChangeNameModal}>
          <ChangeName
            url={"projects"}
            collectionType={"project"}
            currentJSON={project}
            setCloseModal={setOpenChangeNameModal}
          />
        </ModalWrapper>
      </NormalModal>
      <ShareItemModal open={openShareModal} setOpen={setOpenShareModal} />
    </>
  );
};

export default ProjectComponent;
