import React, { useContext, useEffect, useState } from "react";
import s from "./MainNav.module.scss";
import { navigationMenu, iconMap } from "./navigation";
import { LinkItem, TNavigationItem } from "./type";
import AppContext from "../../../core/context/AppContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Biz from "../../Biz/Biz";
import AuthContext from "../../../core/context/AuthContext";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as DashboardIcon } from "../../../assets/svg/Dashboard.svg";
import { ReactComponent as CloudStorageIcon } from "../../../assets/svg/CloudStorageIcon.svg";
import NavFolderTreeStructure from "../../FolderComponents/NavFolderTreeStructure/NavFolderTreeStructure";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AnimatePresence, motion } from "framer-motion"; // Import Framer Motion
import { useApiRequest } from "../../../core/api/useApiRequest";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { variantsFolders, variantsNav } from "./variants";

const MainNav = () => {
  const { t } = useTranslation();
  const { setFolderPath, openNav, lastDesignStudioLink, setOpenLeftBar } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);
  const { setActiveGlobalState, setCreatingNewWork } =
    useContext(FabricJSContextNew);
  const location = useLocation();
  const [openFolderStructure, setOpenFolderStructure] =
    useState<boolean>(false);

  const [selectedNavLink, setSelectedNavLink] = useState<string>("");
  const [selectedNavLinkRouterPath, setSelectedNavLinkRouterPath] =
    useState<string>("");

  const designStudioLink = lastDesignStudioLink || "design-studio";
  const [selectedFolder, setSelectedFolder] = useState<any[]>([]);
  const navigate = useNavigate();
  const { sendRequest } = useApiRequest();

  useEffect(() => {
    if (openFolderStructure && !openNav) closeFolderStructureHandler();
  }, [openNav]);

  const handleNavClick = (itemName: string) => {
    setFolderPath([]);
    if (itemName === "Design") {
      handleCreateNewProject();
    }
    // Add any additional logic you want to execute for all nav clicks
  };
  const handleCreateNewProject = async () => {
    try {
      const response = await sendRequest("post", `creator/2d/projects`, {
        name: "",
      });

      if (response && response.data) {
        const responseData = response.data as any;
        setActiveGlobalState({
          canvasType: "projects",
          fullJSON: responseData,
          selectedView: responseData?.versions[0],
        });

        navigate(`/design-studio/project/${responseData?.token}&is_draft=true`);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  const openFolderStructureHandler = (navName: string) => {
    setSelectedNavLink(navName);
    if (navName === "Products") {
      setOpenFolderStructure(true);
      setSelectedNavLinkRouterPath("templates");
    } else {
      setOpenFolderStructure(true);
      setSelectedNavLinkRouterPath(navName.toLowerCase());
    }
  };

  const closeFolderStructureHandler = () => {
    setOpenFolderStructure(false);
  };
  const [activeLink, setActiveLink] = useState<string>("");

  useEffect(() => {
    setOpenLeftBar(false);
  }, []);

  const renderAnimatedText = (text: string) => {
    return (
      <motion.span
        initial={{ opacity: 1, width: "auto" }}
        animate={{
          opacity: openNav ? 1 : 0, // Fade out text
          width: openNav ? "auto" : 0, // Collapse width
        }}
        transition={{ duration: 0.2 }}
        style={{
          overflow: "hidden", // Prevent layout breaking
          whiteSpace: "nowrap", // Ensure text doesn't wrap
        }}
      >
        {text}
      </motion.span>
    );
  };

  const renderLink = (item: LinkItem, i: number) => {
    const Icon = item.ava ? iconMap[item.ava] : null;
    const isDisabled = user?.subscription?.plan?.level === 0 && item.biz;

    return (
      <motion.div
        animate={{
          overflow: "hidden",
        }}
        className={`${s.nav_item} ${
          location.pathname === item.link ? s.active : ""
        }`}
        key={i}
      >
        {!openFolderStructure ? (
          <Link
            to={item.link}
            className={isDisabled ? `${s.disabled}` : ""}
            onClick={() => {
              handleNavClick(item.name);
              setActiveLink(item.name);
            }}
          >
            {Icon && <Icon className={s.icon} fontSize="medium" />}
            {renderAnimatedText(t(item?.name))}
            {item?.biz && user?.subscription?.plan?.level === 0 ? (
              <Biz />
            ) : null}
            {item?.hasFolderStructure &&
            (activeLink === item?.name || location.pathname === item.link) &&
            openNav ? (
              <ChevronRightIcon
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigation
                  openFolderStructureHandler(item?.name);
                }}
                sx={{ marginLeft: "auto" }}
              />
            ) : null}
          </Link>
        ) : null}
      </motion.div>
    );
  };

  function isLinkItem(item: TNavigationItem): item is LinkItem {
    return item?.isLink === true;
  }

  const renderNavItems = (items: TNavigationItem[]) => {
    return items.map((item: TNavigationItem, i: number) => {
      if (isLinkItem(item)) {
        // For link items, the key is already set in renderLink.
        return renderLink(item, i);
      } else {
        // For group items, wrap the returned elements in a React Fragment with a key.
        return (
          <React.Fragment key={i}>
            <AnimatePresence initial={false}>
              <motion.div
                className={s.nav_group}
                animate={{
                  marginTop: openNav ? "16px" : "0px",
                  marginBottom: openNav ? "16px" : "0px",
                }}
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: openNav ? "100%" : "0%",
                    width: openNav ? "100%" : "0%",
                  }}
                  transition={{ duration: 0.2, delay: 0.3 }}
                >
                  <h5>{t(item.name).toUpperCase()}</h5>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: !openNav ? "100%" : "0%",
                    width: !openNav ? "100%" : "0%",
                  }}
                  transition={{ duration: 0.2, delay: 0.3 }}
                >
                  <div className={s.line_break}></div>
                </motion.div>
              </motion.div>

              {item?.children?.map((childItem, j: number) => {
                if (isLinkItem(childItem)) {
                  return renderLink(childItem, j);
                } else {
                  // If you have nested groups or other non-link items, make sure to add keys similarly.
                  return null;
                }
              })}
            </AnimatePresence>
          </React.Fragment>
        );
      }
    });
  };
  const splitLocation = location.pathname.split("/")[1];
  const renderFolderStructure = (returnNavProp: string) => {
    return (
      <>
        {/* Return to dashboard */}
        <div className={s.nav_item} onClick={closeFolderStructureHandler}>
          <Link to={`/${selectedNavLinkRouterPath}`}>
            <ChevronLeftIcon />
            {t("ReturnToDashboard")}
          </Link>
        </div>

        <NavFolderTreeStructure
          requestPath={selectedNavLinkRouterPath}
          setSelectedFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
          header={`${
            splitLocation.charAt(0).toUpperCase() + splitLocation.slice(1)
          } Folders`}
          dndType={splitLocation}
        />
        {/* Folder structure items */}
      </>
    );
  };

  const renderDiskSpace = () => {
    return (
      <motion.div
        className={s.disk_space}
        animate={{
          paddingBottom: openNav ? "21px" : "0px",
        }}
      >
        <motion.div
          animate={{
            paddingLeft: openNav ? "22px" : "12px",
          }}
          className={s.top}
        >
          <CloudStorageIcon className={s.icon} fontSize="medium" />
          {renderAnimatedText(t("Disk Space"))}
        </motion.div>
        <div className={s.line_break}></div>
        <div className={s.bottom}>
          <motion.div
            className={s.used_disk_space}
            animate={{
              marginInline: openNav ? "29px" : "7px",
            }}
            initial={{ marginInline: "29px" }}
            transition={{ duration: 0.2, delay: 0.2 }}
          >
            {/* TODO: use actual disk size values from the user */}
            <div style={{ width: `${Math.floor((4.53 / 10) * 100)}%` }}></div>
          </motion.div>
          {renderAnimatedText(
            `${t("Used")} ${"4.53"} GB ${t("Out of")} ${"10"} GB`
          )}
        </div>
      </motion.div>
    );
  };

  const direction = openFolderStructure ? 1 : -1; // 1 for forward, -1 for backward
  return (
    <div className={`${s.container} ${openNav ? "" : s.hide}`}>
      {/* Dashboard at the top */}
      <div
        className={`${s.nav_item} ${
          location.pathname === "/dashboard" ? s.active : ""
        }`}
      >
        <Link
          to="/dashboard"
          onClick={() => {
            closeFolderStructureHandler();
            setActiveLink("Dashboard");
          }}
        >
          <DashboardIcon className={s.icon} fontSize="medium" />
          {openNav && renderAnimatedText(t("Dashboard"))}
        </Link>
      </div>
      <div className={`${s.content}`}>
        <AnimatePresence>
          {!openFolderStructure && (
            <motion.div
              key="navItems"
              custom={direction}
              variants={variantsNav}
              initial="enter"
              animate="center"
              exit="exit"
            >
              <div className={s.top}>
                {renderNavItems(navigationMenu(designStudioLink))}
              </div>
              <div className={s.bottom}>{renderDiskSpace()}</div>
            </motion.div>
          )}
          {openFolderStructure && (
            <motion.div
              key="folderStructure"
              custom={direction}
              variants={variantsFolders}
              initial="enter"
              animate="center"
              exit="exit"
            >
              <div className={s.top}>
                {renderFolderStructure(selectedNavLink.toLowerCase())}
              </div>
              <div className={s.bottom}>{renderDiskSpace()}</div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default MainNav;
