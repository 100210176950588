import React, { useContext, useEffect, useState } from "react";

import s from "./ProjectsDashboard.module.scss";
import ProjectComponent from "../../components/ProjectComponent.tsx/ProjectComponent";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

import { useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FabricJSContextNew from "../../core/context/FabricJSContextNew";
import AddIconButton from "../../components/AddIconButton/AddIconButton";
import SlideUpModal from "../../components/Modals/Modal/SildeUpModal";
import FilterComponent from "../../components/FilterComponent/FilterComponent";
import handleLoadCanvasProject from "../Fabric-JS/fabric-functions/LoadProject";
import NormalModal from "../../components/Modals/Modal/NormalModal";
import ModalWrapper from "../../components/Modals/Modal.wrapper";
import CreateFolder from "../../components/Modals/FolderModals/CreateFolder/CreateFolder";
import { Draggable, Droppable } from "react-beautiful-dnd";

import FolderNodeTree from "../../components/FolderComponents/FolderNodeThree/FolderNodeTree";
import AppContext from "../../core/context/AppContext";
import CreateProject from "../../components/Modals/CreateProject/CreateProject";
import { ReactComponent as AddFolderIcon } from "../../assets/svg/AddFolderIcon.svg";

const ProjectsDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const splitLocation = location.pathname.split("/")[1];

  const { folderPath, setFolderPath, currentFolder, isBeforeCapture } = useContext(AppContext);
  const [openCreateProject, setOpenCreateProject] = useState<boolean>(false);
  const [openCreateFolder, setOpenCreateFolder] = useState<boolean>(false);
  const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);

  const { canvasContext, setActiveGlobalState } = useContext(FabricJSContextNew);

  const projectHandler = (selectedView: any, wholeProject: any) => {
    handleLoadCanvasProject(canvasContext, selectedView);
    setActiveGlobalState((prevState: any) => ({
      ...prevState,
      canvasType: "projects",
    }));

    navigate(`/design-studio/project/${wholeProject?.token}`);
  };

  useEffect(() => {
    if (folderPath.length > 0) {
      const lastFolderInArray: number = folderPath[folderPath.length - 1]?.id;

      setCurrentFolderId(lastFolderInArray);
    } else {
      setCurrentFolderId(null);
    }
  }, [folderPath]);

  return (
    <>
      <div className={s.container}>
        <h3>{t("Projects")}</h3>
        <h4>{t("LastUsed")}</h4>
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/creator/2d/projects"
            defaultSort="id"
            defaultPagination={5}
            isPagination={false}
            defaultFilter={"is_draft:false"}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProjectComponent
                    key={`dashboard-projects-projects-recent-map${i}`}
                    project={el}
                    variant="medium"
                    handleClick={(variant) => projectHandler(variant, el)}
                    id={`dashboard-projects-projects-recent-map${i}`}
                    componentOptions="1"
                  />
                ))}
              </div>
            )}
          />
        </div>
        <h4>{currentFolder ? currentFolder.name : t("All")}</h4>
        <div className={s.folder_div}>
          <FolderNodeTree
            requestPath="projects"
            routerPath="projects"
            folderPath={folderPath}
            setFolderPath={setFolderPath}
            variant="medium"
          />
        </div>
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/creator/2d/projects"
            defaultSort="id"
            defaultPagination={20}
            category_id={currentFolderId?.toString()}
            isPagination={true}
            defaultFilter={"is_draft:false"}
            render={({ data, onDelete }) => (
              <Droppable droppableId="project-list" type={splitLocation} isDropDisabled={true}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <div className={s.map_container}>
                      {data?.map((el: any, i: number) => (
                        <Draggable
                          key={`dashboard-project-projects-all-map-${el.id}`}
                          draggableId={`project-${el.id}`}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                transform: snapshot.isDragging
                                  ? `${provided.draggableProps.style?.transform} scale(0.2)`
                                  : provided.draggableProps.style?.transform,
                                height: snapshot.isDragging ? "15px" : "auto",
                                width: snapshot.isDragging ? "15px" : "auto",

                                transition: "transform 0.1s ease, height 0.1s ease, width 0.1s ease",
                              }}
                            >
                              <ProjectComponent
                                key={`dashboard-projects-projects-all-map${i}`}
                                project={el}
                                variant="medium"
                                handleClick={(variant) => projectHandler(variant, el)}
                                id={`dashboard-projects-projects-all-map${i}`}
                                componentOptions="1"
                                onDelete={onDelete}
                                isDnD={true}
                                isDragging={isBeforeCapture}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                    <div style={{ display: "none" }}>{provided.placeholder}</div>
                  </div>
                )}
              </Droppable>
            )}
          />
        </div>
      </div>
      <div className={s.button_div}>
        <AddIconButton handleClick={() => setOpenCreateProject(true)} Icon={NoteAddIcon} tooltip={t("New project")} />
        <AddIconButton handleClick={() => setOpenCreateFolder(true)} Icon={AddFolderIcon} tooltip={t("New folder")} />
      </div>
      <SlideUpModal open={openCreateProject} onClose={() => setOpenCreateProject(false)}>
        <CreateProject />
      </SlideUpModal>
      <NormalModal open={openCreateFolder} setOpen={setOpenCreateFolder}>
        <ModalWrapper setOpen={setOpenCreateFolder}>
          <CreateFolder
            setCloseModal={setOpenCreateFolder}
            parent_id={currentFolderId || 0}
            postCreateFolderPath="projects"
          />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default ProjectsDashboard;
