import React, { useContext, useState } from "react";
import s from "./GraphicComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
import SelectOptionWrapper from "../SelectOption/SelectOptionWrapper";
import { useApiRequest } from "../../core/api/useApiRequest";
import {
  uniqueID,
  useNotification,
} from "../../core/context/notifications/NotificationProvider";
import { useTranslation } from "react-i18next";
import NormalModal from "../Modals/Modal/NormalModal";
import ModalWrapper from "../Modals/Modal.wrapper";
import ChangeName from "../Modals/ChangeName/ChangeName";
import AppContext from "../../core/context/AppContext";
import ShareItemModal from "../Modals/ShareItemModal/ShareItemModal";
const noBacgroundComponent = {
  background: "transparent",
};
interface IGraphicComponent {
  variant: string;
  handleClick: () => void;
  id: string;
  imgSrc: string;
  componentOptions: string;
  onDelete?: (id: number) => void; // Add onDelete prop
  graphic: any;
}
const selectStyle: React.CSSProperties = {
  position: "absolute",
  top: "0",
  right: "10px",
  zIndex: "2",
};

const GraphicComponent = ({
  handleClick,
  variant,
  imgSrc,
  id,
  componentOptions,
  onDelete,
  graphic,
}: IGraphicComponent) => {
  const [openMore, setOpenMore] = useState<boolean>(false);
  const [openChangeNameModal, setOpenChangeNameModal] =
    useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const { t } = useTranslation();
  const { sendRequest } = useApiRequest();
  const notification = useNotification();
  const { setForceCollectionRefetch } = useContext(AppContext);

  const deleteGraphic = async () => {
    try {
      const response = await sendRequest("delete", `/media/${graphic?.id}`);

      console.log(response);

      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Product Deleted",
      });

      if (onDelete) {
        onDelete(graphic.id);
        setForceCollectionRefetch((prev) => ({
          refetch: !prev.refetch,
          type: "graphic",
        }));
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setOpenMore((prevState) => !prevState);
    }
  };
  const changeName = async () => {
    setOpenChangeNameModal((prevState) => !prevState);
  };
  const options = [
    [
      {
        icon: "open",
        function: null,
        name: t("Open"),
      },
    ],
    [
      {
        icon: "changeName",
        function: changeName,
        name: t("Change name"),
      },
      {
        icon: "download",
        function: null,
        name: t("Download"),
      },
    ],
    [
      {
        icon: "share",
        function: () => setOpenShareModal(true),
        name: t("Share"),
      },
      {
        icon: "favourite",
        function: null,
        name: t("Add to favourite"),
      },
    ],
    [
      {
        icon: "delete",
        function: deleteGraphic,
        name: t("Delete"),
      },
    ],
  ];
  return (
    <>
      <div
        className={`${s.container} ${
          variant === "2ex_small"
            ? s.two_ex_small
            : variant === "ex_small"
            ? s.ex_small
            : variant === "small"
            ? s.small
            : variant === "medium"
            ? s.medium
            : variant === "3ex_small"
            ? s.three_ex_small
            : s.large
        }  `}
        onClick={() => handleClick()}
      >
        <ComponentBackground
          id={id}
          styles={variant === "3ex_small" ? noBacgroundComponent : ""}
        >
          <div
            className={`${s.img_container} ${
              variant === "2ex_small"
                ? s.two_ex_small
                : variant === "ex_small"
                ? s.ex_small
                : variant === "small"
                ? s.small
                : variant === "medium"
                ? s.medium
                : variant === "3ex_small"
                ? s.three_ex_small
                : s.large
            }  `}
          >
            <img src={imgSrc} alt={id} />
          </div>
        </ComponentBackground>
        {componentOptions === "1" ? (
          <SelectOptionWrapper
            options={options}
            openMore={openMore}
            setOpenMore={setOpenMore}
            style={selectStyle}
          />
        ) : null}

        <div className={s.info}>
          <span>{graphic?.name}</span>
          <span></span>
        </div>
      </div>
      <NormalModal open={openChangeNameModal} setOpen={setOpenChangeNameModal}>
        <ModalWrapper setOpen={setOpenChangeNameModal}>
          <ChangeName
            url={"templates"}
            collectionType={"graphic"}
            currentJSON={graphic}
            setCloseModal={setOpenChangeNameModal}
          />
        </ModalWrapper>
      </NormalModal>
      <ShareItemModal
        open={openShareModal}
        setOpen={setOpenShareModal}
        isEditable={false}
      />
    </>
  );
};

export default GraphicComponent;
