import React, { useContext, useState } from "react";
import s from "./ChangeName.module.scss";
import ModalHeader from "../ModalHeader";
import { TextField } from "@mui/material";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../../core/api/useApiRequest";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";
import { Collection } from "../../../types/generalTypes";
import AppContext from "../../../core/context/AppContext";

const buttonStyles = {
  marginLeft: "auto",
  width: "200px",
};

interface IChangeName {
  // url is either templates or projects
  url: string;
  // Type of item that is changed
  collectionType: Collection;
  // currentJSON is the activeGlobalState used for (id)
  currentJSON: any;
  setCloseModal: any;
}

const ChangeName = ({
  url,
  collectionType,
  currentJSON,
  setCloseModal,
}: IChangeName) => {
  const { t } = useTranslation();
  const { sendRequest, isLoading } = useApiRequest();
  const [input, setInput] = useState<string>(currentJSON?.name || "");
  const { setForceCollectionRefetch } = useContext(AppContext);
  const notification = useNotification();

  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(e.target.value);
  };

  const changeNameHandler = async () => {
    try {
      const response: any = await sendRequest(
        "put",
        `creator/2d/${url}/${currentJSON.id}`,
        {
          name: input,
        }
      );
      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Name changed",
      });
      setCloseModal((prevState: boolean) => !prevState);
      const updatedData = response.data as any;

      const updateItems = (prevItems: any) =>
        prevItems.map((item: any) =>
          item.id === updatedData.id ? updatedData : item
        );

      setForceCollectionRefetch((prev) => ({
        refetch: !prev.refetch,
        type: collectionType,
      }));
    } catch (err) {
      console.error(err);
    } finally {
      // You can handle any final steps if necessary
    }
  };

  return (
    <div className={s.container}>
      <ModalHeader header={t("ChangeNameModal.Header")} />
      <TextField
        name="name"
        label={t("ChangeNameModal.Name")}
        value={input}
        onChange={inputHandler}
      />
      <Button
        text={t("Save")}
        type="button"
        styles={buttonStyles}
        handleClick={changeNameHandler}
        disabled={isLoading}
      />
    </div>
  );
};

export default ChangeName;
