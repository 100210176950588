import React, { useContext, useEffect, useState } from "react";
import s from "./CreateProject.module.scss";
import RenderActionComponent from "../../RenderActionComponent/RenderActionComponent";
import SearchIcon from "@mui/icons-material/Search";
import ImportFile from "./ImportFile/ImportFile";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "../../Button/Button";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import infoImg from "./ImportFile/assets/info.svg";
import Products from "./Products/Products";
import Papers from "./Papers/Papers";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../../core/api/useApiRequest";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { useNavigate } from "react-router-dom";
import ProjectContext from "../../../core/context/ProjectContext";
import { uniqueID, useNotification } from "../../../core/context/notifications/NotificationProvider";
import FolderTreeStructure from "../../FolderComponents/FolderTreeStructure/FolderTreeStructure";

const initialValues = {
  projectName: "",
};
interface TCreateProject {
  setCloseModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateProject = ({ setCloseModal }: TCreateProject) => {
  const { t } = useTranslation();
  const nav = [
    // { link: "search", name: "Search" },
    // { link: "last_used", name: "LastUsed" },
    { link: "import_file", name: "ImportFile" },
    { link: "products", name: "Products" },
    { link: "paper", name: "Paper" },
    // { link: "other_size", name: "OtherSizes" },
    // { link: "saved", name: "Saved" },
  ];
  const navigate = useNavigate();
  const notification = useNotification();
  const [choosenFolder, setChoosenFolder] = useState<any>({ id: 0 });
  const { sendRequest, isLoading } = useApiRequest();

  const { setProjects } = useContext(ProjectContext);

  const { canvasContext, setActiveGlobalState, setTempActiveGlobalState, tempActiveGlobalState, setCreatingNewWork } =
    useContext(FabricJSContextNew);

  const [actionState, setActionState] = useState<string>("products");
  const [inputs, setInputs] = useState<any>(initialValues);
  useEffect(() => {
    setInputs((prevInputs: any) => ({
      ...prevInputs,
      name: tempActiveGlobalState.name || "",
    }));
  }, [tempActiveGlobalState]);

  const setAction = (newAction: string) => {
    setActionState(newAction);
  };

  const inputHandler = (e: any) => {
    const { name, value } = e.target;
    setInputs((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

    setTempActiveGlobalState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const actions: Record<string, JSX.Element> = {
    // search: <div>{t("Search")}</div>,
    // last_used: <div>{t("LastUsed")}</div>,
    import_file: <ImportFile />,
    products: (
      <Products
        setTempActiveGlobalState={setTempActiveGlobalState}
        handleDoubleClick={() => handleCreateNewProject()}
      />
    ),
    paper: <Papers />,
    // other_size: <div>{t("OtherSizes")}</div>,
    // saved: <div>{t("Saved")}</div>,
  };

  //POST CREATE PROJECT
  const handleCreateNewProject = async () => {
    console.log("Do i even get here!?????");
    try {
      const response = await sendRequest("post", `creator/2d/projects`, {
        template_id: tempActiveGlobalState?.fullJSON?.id,
        name: inputs.projectName,
        category_id: choosenFolder?.id,
      });
      console.log(response);

      if (response && response.data) {
        notification({
          id: uniqueID(),
          type: "SUCCESS",
          message: t("Create.ProjectDrafCreated"),
        });
        const responseData = response.data as any;

        setActiveGlobalState({
          fullJSON: responseData,
          canvasType: "projects",
          selectedView: {
            ...responseData.versions[0],

            view_uuid: tempActiveGlobalState.selectedView.uuid,
          },
          generateThumbnail: null,
        });

        setProjects((prevProjects: any) => [...prevProjects, responseData]);
        setCreatingNewWork((prevState: any) => ({
          ...prevState,
          workType: "project",
          new: true,
        }));

        navigate(`/design-studio/project/${responseData?.token}&is_draft=true`);
        if (setCloseModal) {
          setCloseModal((prevState: boolean) => !prevState);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  return (
    <div className={s.container}>
      <div className={s.header}>
        <h4>{t("New project").toLocaleUpperCase()}</h4>
      </div>
      <div className={s.content}>
        <div className={s.main}>
          <div className={s.nav}>
            <SearchIcon />
            {nav.map((el, i: number) => (
              <span
                className={actionState === el.link ? s.active : undefined}
                key={`Create-project-map-${i}`}
                onClick={() => setAction(el.link)}
              >
                {t(el.name)}
              </span>
            ))}
          </div>
          <RenderActionComponent actions={actions} action={actionState} />
        </div>
        <div className={s.right}>
          <div className={s.header}>
            <h4>{t("Information")}</h4>
            <div className={s.img_container}>
              <img src={infoImg} alt={infoImg} />
            </div>
          </div>

          <TextField
            name="projectName"
            label={t("Name")}
            value={inputs.projectName}
            onChange={(e) => inputHandler(e)}
          />

          <FolderTreeStructure requestPath="projects" setSelectedFolder={setChoosenFolder} />

          <div className={s.header}>
            <h4>{t("Working area")}</h4>
            <div className={s.img_container}>
              <img src={infoImg} alt={infoImg} />
            </div>
          </div>

          <div className={s.orientation}>
            <div className={s.header}>
              <h4>{t("Working area")}</h4>
              <div className={s.img_container}>
                <img src={infoImg} alt={infoImg} />
              </div>

              <div className={s.paper_orientation}>
                <div className={s.r}>
                  <span>R</span>
                </div>
              </div>

              <div className={s.paper_orientation}>
                <div className={s.l}>
                  <span>L</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.bottom}>
        <div>
          <ChevronLeftIcon />
          <a>Powrót</a>
        </div>

        <a href="">Zapisz szablon</a>

        <Button type="button" text={"send"} handleClick={() => handleCreateNewProject()} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default CreateProject;
