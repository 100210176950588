import React, { useContext, useEffect, useState } from "react";
import { useApiRequest } from "../../../../../../core/api/useApiRequest";
import FilterComponent from "../../../../../FilterComponent/FilterComponent";
import ProductComponent from "../../../../../ProductComponent/ProductComponent";
import s from "./ContentLeftBar.module.scss";
import FabricJSContextNew from "../../../../../../core/context/FabricJSContextNew";
import handleLoadCanvasProject from "../../../../../../features/Fabric-JS/fabric-functions/LoadProject";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IFolder } from "../../../../../../core/types/types";
interface IContentLeftBar {
  folderRequestPath: string;
  filterComponentRequestPath: string;
  setSelectedFolder: any;
}
const ContentLeftBar = ({
  folderRequestPath,
  filterComponentRequestPath,
  setSelectedFolder,
}: IContentLeftBar) => {
  const { sendRequest } = useApiRequest();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [allFolders, setAllFolders] = useState([]);
  const { canvasContext, setActiveGlobalState } = useContext(FabricJSContextNew);
  const fetchAllFolders = async () => {
    const { data }: any = await sendRequest("get", `creator/2d/${folderRequestPath}/categories`);
    console.log(data, "content left bar");
    setAllFolders(data);
  };

  useEffect(() => {
    fetchAllFolders();
  }, []);

  const projectHandler = (selectedView: any, wholeProduct: any) => {
    handleLoadCanvasProject(canvasContext, selectedView);
    setActiveGlobalState({ canvasType: "templates", fullJSON: wholeProduct });
    navigate(`/design-studio/template/${wholeProduct?.token}`);
  };

  return (
    <div className={s.container}>
      {allFolders.map((folder: IFolder, i: number) => (
        <div key={`left-bar-unique-${i}`} className={s.folder_map_container}>
          <div className={s.info} onClick={() => setSelectedFolder(folder)}>
            <span>{folder?.name}</span>
            <span>{t("Show all")}</span>
          </div>

          <FilterComponent
            endpoint={`/creator/2d/${filterComponentRequestPath}`}
            defaultSort="-id"
            defaultPagination={10}
            isPagination={true}
            defaultFilter={"is_draft:false"}
            category_id={folder?.id.toString()} // Use category_id for filtering
            render={({ data, onDelete }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProductComponent
                    key={`dashboard-product-products-recent-map${i}`}
                    product={el}
                    variant="3ex_small"
                    handleClick={(variant) => projectHandler(variant, el)}
                    componentOptions="2"
                    id={`dashboard-product-products-recent-map${i}`}
                    onDelete={onDelete}
                  />
                ))}
              </div>
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default ContentLeftBar;
