//This component is used as a wrapper for select option in case
//we need it to hide and use toggle logic
import React, { SetStateAction, useEffect, useRef, useState } from "react";
import s from "./SelectOptionWrapper.module.scss";
import SelectOption from "./SelectOption";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { createPortal } from "react-dom";

interface ISelectOptionWrapper {
  options: any;
  openMore: boolean;
  setOpenMore: React.Dispatch<SetStateAction<boolean>>;
  style: React.CSSProperties;
  iconSize?: "small" | "large" | "inherit" | "medium";
}
type CursorPosition = {
  x: number;
  y: number;
} | null;

const SelectOptionWrapper = ({
  options,
  openMore,
  setOpenMore,
  style,
  iconSize,
}: ISelectOptionWrapper) => {
  const selectOptionRef = useRef<SVGSVGElement>(null);
  const selectOptionContainerRef = useRef<HTMLDivElement>(null);
  const [cursorPosition, setCursorPosition] = useState<CursorPosition>(null);

  useEffect(() => {
    const handleActionOutside = (event: any) => {
      if (
        selectOptionContainerRef.current &&
        !selectOptionContainerRef.current.contains(event.target as Node) &&
        event.target !== selectOptionRef.current
      ) {
        setOpenMore(false);
      }
    };
    // Close the menu on any action outside it
    const events = ["mousedown", "keydown", "scroll", "touchstart", "wheel"];

    events.forEach((event) =>
      window.addEventListener(event, handleActionOutside)
    );
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActionOutside)
      );
    };
  }, []);

  // Show popup item menu in cursor position and ensure it stays inside the viewport
  useEffect(() => {
    if (cursorPosition && selectOptionContainerRef.current && openMore) {
      selectOptionContainerRef.current.style.display = "block";

      let popupWidth = selectOptionContainerRef.current.offsetWidth;
      let popupHeight = selectOptionContainerRef.current.offsetHeight;

      let mouseX = cursorPosition.x;
      let mouseY = cursorPosition.y;

      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let left = mouseX;
      let top = mouseY;

      if (mouseX + popupWidth > windowWidth) {
        left = windowWidth - popupWidth - 10; // 10px padding
      }

      if (mouseY + popupHeight > windowHeight) {
        top = windowHeight - popupHeight - 10; // 10px padding
      }

      selectOptionContainerRef.current.style.left = left + "px";
      selectOptionContainerRef.current.style.top = top + "px";
    }
  }, [cursorPosition]);

  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (!openMore) setCursorPosition({ x: event.pageX, y: event.pageY });
    setOpenMore(!openMore);
  };
  return (
    <div className={s.openMore} style={style}>
      <MoreHorizIcon
        fontSize={iconSize ?? "large"}
        onClick={handleClick}
        ref={selectOptionRef}
      />
      {openMore
        ? createPortal(
            <SelectOption
              optionGroups={options}
              style={style}
              ref={selectOptionContainerRef}
            />,
            document.body
          )
        : null}
    </div>
  );
};

export default SelectOptionWrapper;
