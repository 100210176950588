import { ReactComponent as OpenIcon } from "../../assets/svg/options/Open.svg";
import { ReactComponent as ChangeNameIcon } from "../../assets/svg/options/ChangeName.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/options/Download.svg";
import { ReactComponent as ShareIcon } from "../../assets/svg/options/Share.svg";
import { ReactComponent as FavouriteIcon } from "../../assets/svg/options/Favourite.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/options/Trash.svg";

export const iconMap: any = {
  open: OpenIcon,
  changeName: ChangeNameIcon,
  download: DownloadIcon,
  share: ShareIcon,
  favourite: FavouriteIcon,
  delete: DeleteIcon,
};
