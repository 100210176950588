import React, { useEffect, useRef, useState } from 'react';
import './Scrollbar.scss';

const Scrollbar = ({ children }: { children: React.ReactNode }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const scrollTrackRef = useRef<HTMLDivElement>(null);
    const scrollThumbRef = useRef<HTMLDivElement>(null);
    const observer = useRef<ResizeObserver | null>(null);

    const [thumbHeight, setThumbHeight] = useState(20);
    const [isDragging, setIsDragging] = useState(false);
    const [scrollStartPosition, setScrollStartPosition] = useState<number>(0);
    const [initialContentScrollTop, setInitialContentScrollTop] =
        useState<number>(0);

    function handleResize() {
        setThumbHeight(293);
    }

    function handleThumbPosition() {
        if (
            !contentRef.current ||
            !scrollTrackRef.current ||
            !scrollThumbRef.current
        ) {
            return;
        }

        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const { clientHeight: trackHeight } = scrollTrackRef.current;

        let newTop =
            (scrollTop / (scrollHeight - clientHeight)) * (trackHeight - 293);

        newTop = Math.min(newTop, trackHeight - 293);

        const thumb = scrollThumbRef.current;
        requestAnimationFrame(() => {
            thumb.style.top = `${newTop}px`;
        });
    }

    useEffect(() => {
        if (contentRef.current) {
            const content = contentRef.current;
            observer.current = new ResizeObserver(() => {
                handleResize();
            });
            observer.current.observe(content);
            content.addEventListener('scroll', handleThumbPosition);
            return () => {
                observer.current?.unobserve(content);
                content.removeEventListener('scroll', handleThumbPosition);
            };
        }
    }, []);

    function handleThumbMousedown(e: React.MouseEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setScrollStartPosition(e.clientY);
        if (contentRef.current)
            setInitialContentScrollTop(contentRef.current.scrollTop);
        setIsDragging(true);
    }

    function handleThumbMouseup(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (isDragging) {
            setIsDragging(false);
        }
    }

    function handleThumbMousemove(e: MouseEvent) {
        if (contentRef.current) {
            e.preventDefault();
            e.stopPropagation();
            if (isDragging) {
                const {
                    scrollHeight: contentScrollHeight,
                    clientHeight: contentClientHeight,
                } = contentRef.current;

                const deltaY =
                    (e.clientY - scrollStartPosition) *
                    (contentClientHeight / thumbHeight);

                const newScrollTop = Math.min(
                    initialContentScrollTop + deltaY,
                    contentScrollHeight - contentClientHeight
                );

                contentRef.current.scrollTop = newScrollTop;
            }
        }
    }

    useEffect(() => {
        document.addEventListener('mousemove', handleThumbMousemove);
        document.addEventListener('mouseup', handleThumbMouseup);
        return () => {
            document.removeEventListener('mousemove', handleThumbMousemove);
            document.removeEventListener('mouseup', handleThumbMouseup);
        };
    }, [handleThumbMousemove, handleThumbMouseup]);

    function handleTrackClick(e: React.MouseEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        const { current: track } = scrollTrackRef;
        const { current: content } = contentRef;
        if (track && content) {
            const { clientY } = e;
            const target = e.target as HTMLDivElement;
            const rect = target.getBoundingClientRect();
            const trackTop = rect.top;
            const thumbOffset = -(thumbHeight / 2);
            const clickRatio =
                (clientY - trackTop + thumbOffset) / track.clientHeight;
            const scrollAmount = Math.floor(clickRatio * content.scrollHeight);
            content.scrollTo({
                top: scrollAmount,
                behavior: 'smooth',
            });
        }
    }

    return (
        <div className="container">
            <div className="content" id="custom-scrollbars-content" ref={contentRef}>
                {children}
            </div>
            <div className="scrollbar">
                <div
                    className="track-and-thumb"
                    role="scrollbar"
                    aria-controls="custom-scrollbars-content"
                >
                    <div
                        className="track"
                        ref={scrollTrackRef}
                        onClick={handleTrackClick}
                        style={{ cursor: isDragging ? 'grabbing' : undefined }}
                    ></div>
                    <div
                        className="thumb"
                        ref={scrollThumbRef}
                        onMouseDown={handleThumbMousedown}
                        style={{
                            height: `${thumbHeight}px`,
                            cursor: isDragging ? 'grabbing' : 'grab',
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default Scrollbar;
