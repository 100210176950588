import React, { useContext } from "react";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import SingleComponent from "../../../../../components/SingleComponent/SingleComponent";
import { filterVersionToShow } from "../../../../../helper/filterVersionToShow";
import handleLoadCanvasProject from "../../../fabric-functions/LoadProject";
import s from "../FabricJS.module.scss";
import { filterViewToShow } from "../../../../../components/ProductComponent/filterViewToShow";
import { updateCanvasObjectsList } from "../../../fabric-functions/UpdateCanvasObjectList";
const AvailableViewsPanel = () => {
  const selectVersion = (data: any) => {
    if (activeGlobalState.selectedView.uuid === data.uuid) {
      return;
    }
    setActiveGlobalState((prevState: any) => {
      const newState = {
        ...prevState,
        selectedView: data,
      };
      //guard so that state wont reload if someone clicks the same view
      const projectToShow = filterVersionToShow(
        newState.selectedView?.view_uuid,
        newState.selectedView?.uuid,
        newState.fullJSON,
        true
      );
      handleLoadCanvasProject(canvasContext, projectToShow.canvasJSON);
      return newState;
    });
  };

  const selectView = (data: any) => {
    if (activeGlobalState.selectedView.uuid === data.uuid) {
      return;
    }
    setActiveGlobalState((prevState: any) => {
      const newState = {
        ...prevState,
        selectedView: data,
        canvasType: "projects",
        createVersionFromView: true,
      };

      const productToShow = filterViewToShow(
        newState.selectedView?.uuid,
        newState.fullJSON,
        activeGlobalState.canvasType,
        true
      );

      handleLoadCanvasProject(canvasContext, productToShow.canvasJSON);
      updateCanvasObjectsList(canvasContext, setCanvasObjectList);

      return newState;
    });
  };

  const { canvasContext, activeGlobalState, setActiveGlobalState, setCanvasObjectList } =
    useContext(FabricJSContextNew);

  return (
    <div className={s.views_preview}>
      {activeGlobalState?.canvasType === "projects" &&
        activeGlobalState?.fullJSON?.template?.views.map((el: any, i: number) => (
          <div key={`fabric-js-map-pages-${i}`}>
            <SingleComponent
              key={`fabric-js-map-pages-${i}`}
              variant="small"
              handleClick={(selectedViewToShow) => selectView(selectedViewToShow)}
              data={el}
            />
          </div>
        ))}
    </div>
  );
};

export default AvailableViewsPanel;
