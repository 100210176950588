import { fabric } from "fabric";
import updateClipPath from "./UpdateClipPath";
import { centerCanvasAtOrigin } from "./LoadProject";

const exportCanvasToImage = async (canvas: any): Promise<void> => {
  if (!canvas) {
    console.log("Canvas not provided");
    return;
  }

  let workingArea: any;

  const objectsToExport: any = canvas.getObjects().filter((obj: any) => {
    if (obj.get("level") === 6) {
      workingArea = obj;
    }
    return [1, 2, 4, 5].includes(obj.get("level"));
  });

  if (!workingArea) {
    console.log("No working area found");
    return;
  }

  const tempCanvas: any = new fabric.StaticCanvas(null, {
    width: canvas.width,
    height: canvas.height,
  });

  // Center the temp canvas at the origin
  const center = tempCanvas.getCenter();
  tempCanvas.viewportTransform[4] = center.left;
  tempCanvas.viewportTransform[5] = center.top;

  objectsToExport.forEach((obj: any) => {
    updateClipPath(tempCanvas, obj);
  });

  for (const obj of objectsToExport) {
    await new Promise<void>((resolve) => {
      obj.clone((clonedObj: any) => {
        tempCanvas.add(clonedObj);
        resolve();
      });
    });
  }

  tempCanvas.renderAll();

  const exportedPNG = tempCanvas.toDataURL({
    format: "png",
    quality: 1.0,
  });

  const downloadImage = (dataUrl: string, filename: string = "exported-image.png") => {
    const a = document.createElement("a");
    a.href = dataUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  downloadImage(exportedPNG);

  tempCanvas.dispose();
};

const exportWorkingAreaToImage = async (canvas: any): Promise<void> => {
  if (!canvas) {
    console.log("Canvas not provided");
    return;
  }

  let workingArea: any;

  const objectsToExport: any = canvas.getObjects().filter((obj: any) => {
    if (obj.get("level") === 6) {
      workingArea = obj;
    }
    return [2, 4].includes(obj.get("level"));
  });

  if (!workingArea) {
    console.log("No working area found");
    return;
  }

  // Get absolute bounding box of working area
  const bbox = workingArea.getBoundingRect(true); // true for absolute position
  const padding = 20;

  const exportX = bbox.left - padding;
  const exportY = bbox.top - padding;
  const exportWidth = bbox.width + padding * 2;
  const exportHeight = bbox.height + padding * 2;

  // Create a temp canvas with new dimensions
  const tempCanvas: any = new fabric.StaticCanvas(null, {
    width: exportWidth,
    height: exportHeight,
  });

  // Apply viewportTransform to match original canvas (if necessary)
  tempCanvas.viewportTransform = [1, 0, 0, 1, -exportX, -exportY];

  // Copy objects while maintaining positions
  for (const obj of objectsToExport) {
    await new Promise<void>((resolve) => {
      obj.clone((clonedObj: any) => {
        tempCanvas.add(clonedObj);
        resolve();
      });
    });
  }

  tempCanvas.renderAll();

  const exportedPNG = tempCanvas.toDataURL({
    format: "png",
    quality: 1.0,
  });

  // Download function
  const downloadImage = (dataUrl: string, filename: string = "exported-image.png") => {
    const a = document.createElement("a");
    a.href = dataUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  downloadImage(exportedPNG);

  tempCanvas.dispose();
};

export { exportCanvasToImage, exportWorkingAreaToImage };
