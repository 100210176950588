import React, { useContext, useEffect, useState } from "react";
import s from "./PaymentPlanSelection.module.scss";
import PaymentPlan from "../../../components/PaymentPlan/PaymentPlan";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { dummyPaymentPlans } from "../../../dummy-data/paymentPlans";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { Link, useNavigate } from "react-router-dom";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import AuthContext from "../../../core/context/AuthContext";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { IPlan } from "../../../components/Nav/MainNav/type";
import { PaymentType } from "../../../types/generalTypes";
import SwitcherPaymentType from "../../../components/SwitcherPaymentType/SwitcherPaymentType";
import LoadingState from "../../../components/LoadingState/LoadingState";

const paymentStyles = {
  borderRadius: "32px",
};

interface IPaymentPlanSelection {
  onboard: boolean;
}

const PaymentPlanSelection = ({ onboard }: IPaymentPlanSelection) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setForceFetch, setRegistrationSteps, user } = useContext(AuthContext);
  const [type, setType] = useState<PaymentType>("monthly");
  const [plans, setPlans] = useState<[]>([]);
  const [activePlan, setActivePlan] = useState<{
    index: number;
    name: string;
    level: number;
  }>({
    index: -1,
    name: "",
    level: -1,
  });

  const { sendRequest, isLoading } = useApiRequest();
  const handlePlanClick = (index: number, name: string, level: number) => {
    setActivePlan({
      index,
      name,
      level,
    });
  };

  // const getButtonStyles = (index: number) => {
  //   const styles = {
  //     background: index === activePlan.index ? "gray" : "",
  //     color: index === activePlan.index ? "white" : "",
  //     width: index === activePlan.index ? "220px" : "220px",

  //     margin: "auto",
  //   };

  //   const disabled = index === activePlan.index;

  //   return {
  //     styles,
  //     disabled,
  //   };
  // };
  const fetchPaymentPlan = async () => {
    const { data }: any = await sendRequest("get", "/plans");
    const selected = data.find((plan: any) => plan.level === 1);
    if (selected)
      setActivePlan({
        index: selected.id,
        name: selected.name,
        level: selected.level,
      });
    else {
      setActivePlan({
        index: data[0].id,
        name: data[0].name,
        level: data[0].level,
      });
    }
    setPlans(data);
  };
  useEffect(() => {
    fetchPaymentPlan();
  }, []);

  const handleNextStep = async () => {
    const response = await sendRequest("post", "account/plan", {
      plan_id: activePlan.index,
    });

    console.log(response);
    if (response) {
      setRegistrationSteps((prev: any) => ({
        ...prev,
        plan_id: activePlan.index,
        plan_level: activePlan.level,
        plan_isYearly: type === "yearly",
      }));

      let path;
      if (onboard)
        path =
          activePlan.level === 0
            ? "/onboard/profile-setup"
            : "/onboard/billing-info";
      else
        path =
          activePlan.level === 0
            ? "/subscription/managment"
            : "/change/billing-info";
      setForceFetch((prev: boolean) => !prev);
      navigate(path);
      console.log("Working");
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <div className={s.top}>
            <h2>
              {onboard
                ? t("PlanSelectionComponent.Select subscription")
                : t("PlanSelectionComponent.Change plan")}
            </h2>
            <SwitcherPaymentType type={type} setType={setType} />
          </div>

          <div className={s.inner_container}>
            {isLoading ? (
              <LoadingState />
            ) : (
              <>
                {plans.map((el: any, id) => (
                  <PaymentPlan
                    key={id}
                    backgroundStyles={paymentStyles}
                    el={el}
                    active={activePlan.index === el.id}
                    handleClick={() =>
                      handlePlanClick(el.id, el.name, el.level)
                    }
                    selectedType={type}
                    label={
                      onboard && el.level === 1
                        ? t("PlanSelectionComponent.Best choice")
                        : !onboard && el.id === user.subscription.plan.id
                        ? t("PlanSelectionComponent.Active plan")
                        : null
                    }
                  />
                ))}
              </>
            )}
          </div>
          <div className={s.inner_container_mobile}>
            {isLoading ? (
              <LoadingState style={{ flex: "1" }} />
            ) : (
              <Swiper
                navigation={true}
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true }}
                className={s.subscription_swiper}
              >
                {plans.map((el: any, id) => (
                  <SwiperSlide className={s.swiper_slide} key={id}>
                    <PaymentPlan
                      key={id}
                      backgroundStyles={paymentStyles}
                      el={el}
                      active={activePlan.index === el.id}
                      handleClick={() =>
                        handlePlanClick(el.id, el.name, el.level)
                      }
                      selectedType={type}
                      label={
                        onboard && el.level === 1
                          ? t("PlanSelectionComponent.Best choice")
                          : !onboard && el.id === user.subscription.plan.id
                          ? t("PlanSelectionComponent.Active plan")
                          : null
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          <div className={s.bottom}>
            <Link to={onboard ? "/register" : "/subscription/managment"}>
              &#8592; {t("Go back")}
            </Link>
            <Button
              text={
                isLoading
                  ? t("Loading")
                  : onboard
                  ? t("Continue with") + " " + activePlan.name
                  : t("Change to") + " " + activePlan.name
              }
              type="button"
              handleClick={() => handleNextStep()}
              isLoading={isLoading}
              styles={{ width: "200px" }}
              disabled={
                !onboard &&
                activePlan.index === user.subscription.plan.id &&
                type === user.subscription.plan.type
              }
            />
          </div>
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default PaymentPlanSelection;
