import React, { useContext, useEffect, useState } from "react";
import s from "./Products.module.scss";
import ProductComponent from "../../../../ProductComponent/ProductComponent";
import FolderNodeTree from "../../../../FolderComponents/FolderNodeThree/FolderNodeTree";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import Scrollbar from "../../../../Scrollbar/Scrollbar";

interface IProducts {
    setTempActiveGlobalState: any;
    handleDoubleClick?: () => void;
    isExpanded: boolean;  // Odbieramy stan
}

const Products = ({
                      setTempActiveGlobalState,
                      handleDoubleClick,
                      isExpanded, // Odbieramy stan
                  }: IProducts) => {
    const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);
    const [folderPath, setFolderPath] = useState<any[]>([]);
    const { setStyleIsActive } = useContext(FabricJSContextNew);

    useEffect(() => {
        if (folderPath.length > 0) {
            const lastFolderInArray: number = folderPath[folderPath.length - 1]?.id;
            setCurrentFolderId(lastFolderInArray);
        } else {
            setCurrentFolderId(null);
        }
    }, [folderPath]);

    const handleClick = (selectedView: any, template: any) => {
        setStyleIsActive(template?.id);
        setTempActiveGlobalState({
            fullJSON: template,
            selectedView: selectedView,
        });
    };

    // Wspólny kod dla obu przypadków
    const content = (
        <>
            <div className={s.folder_div}>
                <div className={s.folder_node_container} style={{ height: isExpanded ? 'auto' : '55px' }}>
                    <FolderNodeTree
                        requestPath="templates"
                        folderPath={folderPath}
                        setFolderPath={setFolderPath}
                        variant="medium"
                    />
                </div>
            </div>
            <div
                className={s.product_list}
                style={{ height: isExpanded ? 'calc(100vh - 505px)' : '200px' }}
            >
                <FilterComponent
                    endpoint="/creator/2d/templates"
                    defaultSort="id"
                    defaultPagination={9999}
                    category_id={currentFolderId?.toString()}
                    isPagination={false}
                    enableSordByDate={false}
                    render={({ data }) => (
                        <div className={s.map_container} style={{ height: '200px' }}>
                            {data?.map((el: any, i: number) => (
                                <ProductComponent
                                    key={`dashboard-projects-projects-all-map${i}`}
                                    product={el}
                                    variant="medium"
                                    handleClick={(variant) => handleClick(variant, el)}
                                    id={el?.id}
                                    componentOptions="1"
                                    handleDoubleClick={handleDoubleClick}
                                />
                            ))}
                        </div>
                    )}
                />
            </div>
        </>
    );

    return (
        <div className={`${s.container} container-scrollbar`}>
            {isExpanded ? <Scrollbar>{content}</Scrollbar> : content}
        </div>
    );
};

export default Products;
