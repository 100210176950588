import React, { useEffect, useRef, useState } from "react";
import s from "./PaymentPlan.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

interface IPaymentPlan {
  el: {
    id: number;
    type: string;
    period: string;
    description: string;
    features: [string];
    price: number;
    name: string;
    level: number;
  };
  //el: any;
  backgroundStyles?: React.CSSProperties;
  buttonStyles?: any;
  handleClick: any;
  active: boolean;
  selectedType: "monthly" | "yearly";
  label?: string | null;
}

const PaymentPlan = ({
  el,
  backgroundStyles,
  handleClick,
  active,
  selectedType,
  label = null,
}: IPaymentPlan) => {
  const { t } = useTranslation();
  const [notation, setNotation] = useState<string>("");
  useEffect(() => {
    const newNotaion =
      el.level === 0
        ? t("PlanSelectionComponent.Free forever")
        : el.level === 2
        ? t("PlanSelectionComponent.Enterprise")
        : selectedType === "monthly"
        ? t("PlanSelectionComponent.Monthly recurring")
        : t("PlanSelectionComponent.YearlyPaidOne") +
          el.price * 10 +
          t("PlanSelectionComponent.YearlyPaidTwo");
    setNotation(newNotaion);
  }, [selectedType, el, t]);

  return (
    <ComponentBackground styles={backgroundStyles} hasOutline={false}>
      <div
        className={`${s.container} ${active ? s.active : ""} ${
          label ? s.big : ""
        }`}
        onClick={handleClick}
      >
        {label && <div className={s.container_label}>{label}</div>}
        <div className={s.header}>
          <span>VIDELINK</span>
          <span>{el?.name}</span>
          <p>{el?.description}</p>
        </div>

        <div className={s.price}>
          <div className={s.value}>
            <span>
              {`${
                selectedType === "monthly"
                  ? el?.price * 1
                  : Math.floor((el?.price * 10) / 12)
              }`}{" "}
              PLN
            </span>
            <span>/mc*</span>
          </div>
          <span>*{notation}</span>
        </div>
        <div className={s.perk_container}>
          {el?.features?.map((el: any, id) => (
            <span key={id}>{el.content}</span>
          ))}
        </div>
      </div>
    </ComponentBackground>
    // <ComponentBackground styles={backgroundStyles}>
    //   <div className={s.container}>
    //     <div className={s.header}>
    //       <span>VIDELINK</span>
    //       <span>{el?.type}</span>
    //       <span>{el?.period}</span>
    //     </div>

    //     <p>{el?.description}</p>

    //     <div className={`${s.perk_contanier} ${active ? s.active : ""}`}>
    //       {el?.perks?.map((el: any) => (
    //         <span>{el}</span>
    //       ))}
    //     </div>

    //     <div className={s.show_more} onClick={() => setActive(!active)}>
    //       <KeyboardArrowDownIcon className={active ? s.active : ""} />
    //       <span>pokaz więcej</span>
    //     </div>
    //     <h5>Przestrzeń dyskowa</h5>
    //     <div>
    //       <FormControl fullWidth>
    //         <InputLabel id="demo-simple-select-label" style={{ textAlign: "center" }}>
    //           GB
    //         </InputLabel>
    //         <Select
    //           labelId="demo-simple-select-label"
    //           id="demo-simple-select"
    //           value={age}
    //           label="GB"
    //           size="small"
    //           // onChange={handleChange}
    //         >
    //           <MenuItem value={10}>10GB</MenuItem>
    //           <MenuItem value={20}>20GB</MenuItem>
    //           <MenuItem value={30}>50GB</MenuItem>
    //         </Select>
    //       </FormControl>
    //     </div>
    //     <div className={s.price}>
    //       <span>{el?.price}</span>
    //       <span>PLN</span>
    //       <span>+VAT</span>
    //     </div>
    //     <Button
    //       text={buttonStyles?.text}
    //       type="button"
    //       styles={buttonStyles?.styles}
    //       handleClick={() => handleClick()}
    //     />
    //   </div>
    // </ComponentBackground>
  );
};

export default PaymentPlan;
