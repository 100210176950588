import React, { useContext, useEffect, useState, useTransition } from "react";
import { AnimatePresence, motion } from "framer-motion"; // Import Framer Motion
import DCWrapper from "../../components/DashboardContainerWrapper/DCWrapper";
import s from "./Dashboard.module.scss";
import Add from "./Add/Add";
import ProductList from "./ProductList/ProductList";
import ProjectList from "./ProjectList/ProjectList";
import GraphicList from "./GraphicsList/GraphicList";
import Search from "./Search/Search";
import AppContext from "../../core/context/AppContext";
import RenderActionComponent from "../../components/RenderActionComponent/RenderActionComponent";
import AllSearchList from "./Search/components/AllSearchList.tsx/AllSearchList";
import SearchContext from "../../core/context/SearchContext";
import ProductSearchList from "./Search/components/ProductSearchList/ProductSearchList";
import ProjectSearchList from "./Search/components/ProjectSearchList/ProjectSearchList";
import GraphicSearchList from "./Search/components/GraphicSearchList/GraphicSearchList";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { ReactComponent as PictureIcon } from "../../assets/svg/PictureIcon.svg";
import { ReactComponent as AddFolderIcon } from "../../assets/svg/AddFolderIcon.svg";
import AddIconButton from "../../components/AddIconButton/AddIconButton";
import { useTranslation } from "react-i18next";
import AddGraphic from "../../components/Modals/CreateModals/AddGraphicModal/AddGraphic";
import SlideUpModal from "../../components/Modals/Modal/SildeUpModal";
import NormalModal from "../../components/Modals/Modal/NormalModal";
import ModalWrapper from "../../components/Modals/Modal.wrapper";
import CreateFolder from "../../components/Modals/FolderModals/CreateFolder/CreateFolder";

const actions = {
  all: <AllSearchList />,
  products: <ProductSearchList />,
  projects: <ProjectSearchList />,
  graphics: <GraphicSearchList />,
};

const Dashboard = () => {
  const { isLoopDashboardOpen, setIsLoopDashboardOpen, searchType } = useContext(SearchContext);
  const { t } = useTranslation();
  const [openAddGraphic, setOpenAddGraphic] = useState<boolean>(false);
  const [openCreateFolder, setOpenCreateFolder] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsLoopDashboardOpen(false);
    };
  }, [setIsLoopDashboardOpen]);

  return (
    <>
      <DCWrapper>
        <AnimatePresence>
          {isLoopDashboardOpen ? (
            <motion.div
              key="search"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3, delay: 0.31 },
              }}
              exit={{ opacity: 0, y: 20, transition: { duration: 0.3 } }}
            >
              <Search
                active={isLoopDashboardOpen}
                setActive={setIsLoopDashboardOpen}
                options={["all", "products", "projects", "graphics"]}
                renderContent={() => null} // No specific rendering in Search
              />
              <RenderActionComponent action={searchType} actions={actions} />
            </motion.div>
          ) : (
            <motion.div
              key="dashboard"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3, delay: 0.31 },
              }}
              exit={{ opacity: 0, y: 20, transition: { duration: 0.3 } }}
              className={s.container}
            >
              <div className={s.lists_container}>
                <Add />
                <ProductList />
                <ProjectList />
                <GraphicList />
              </div>
              {/* <div className={s.buttons_container}>
                <AddIconButton
                  handleClick={() => {
                    setOpenAddGraphic(true);
                  }}
                  Icon={PictureIcon}
                  tooltip={t("New graphic")}
                />
                <AddIconButton
                  handleClick={() => {
                    setOpenCreateFolder(true);
                  }}
                  Icon={AddFolderIcon}
                  tooltip={t("New folder")}
                />
              </div> */}
            </motion.div>
          )}
        </AnimatePresence>
      </DCWrapper>

      <SlideUpModal open={openAddGraphic} onClose={() => setOpenAddGraphic(false)}>
        <AddGraphic setOpen={setOpenAddGraphic} />
      </SlideUpModal>
      <NormalModal open={openCreateFolder} setOpen={setOpenCreateFolder}>
        <ModalWrapper setOpen={setOpenCreateFolder}>
          <CreateFolder setCloseModal={setOpenCreateFolder} parent_id={0} postCreateFolderPath="templates" />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default Dashboard;
