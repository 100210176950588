import React, { useState, useRef } from "react";
import s from "./ImportFile.module.scss";
import { useTranslation } from "react-i18next";
import { generateRandomHexString } from "../../../../helper/generateRandomHexString";
import buttonUpload from "../../../../assets/svg/buttonUpload.svg";

interface IImportFile {
  setFileToSend: (file: any) => void;
  onFileSelectedAndInvoke?: (file: any) => void;
  isGraphic?: boolean;
}

const ImportFile: React.FC<IImportFile> = ({
  setFileToSend,
  onFileSelectedAndInvoke,
  isGraphic,
}) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCreateTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name);
      const draft_id = generateRandomHexString(32);
      const fileData = { file, draft_id };

      setFileToSend(fileData);
      if (onFileSelectedAndInvoke) {
        onFileSelectedAndInvoke(fileData);
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className={s.container} onClick={handleClick}>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleCreateTemplate}
      />
      <div>
        <img src={buttonUpload} alt="upload button" />
      </div>
      <div className={s.text}>
        <span>
          {t(
            `${isGraphic ? "AddGraphicComponent" : "ImportFileComponent"}.one`
          )}
        </span>
        <span>
          {t(
            `${isGraphic ? "AddGraphicComponent" : "ImportFileComponent"}.two`
          )}
        </span>
      </div>
    </div>
  );
};

export default ImportFile;
