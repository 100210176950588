import { uniqueID } from "../core/context/notifications/NotificationProvider";
import addGateway from "../features/Fabric-JS/fabric-functions/AddToCanvas/AddGateway";
import { calculateDIP } from "./functions";
import { generateRandomHexString } from "./generateRandomHexString";

// Example shape for the arguments; adjust to fit your actual usage:
interface CreateGraphicArgs {
  event: React.ChangeEvent<HTMLInputElement>;
  user: any;
  sendRequest: any;
  notification: (options: { id: string; type: string; message: string }) => void;
  canvasContext: any | null;
  setCanvasObjectList: (callback: any) => void;
  levelBased: number;
  // If you want to handle state in the parent component, pass setState callbacks:
  setFileToUpload?: React.Dispatch<React.SetStateAction<File | null>>;
  setImageUrl?: React.Dispatch<React.SetStateAction<string>>;
  setAddedImages?: React.Dispatch<React.SetStateAction<any[]>>;
  setFetchGraphics?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * createGraphicHandler
 *
 * Handles the workflow of uploading/reading a file, then adding it to the FabricJS canvas.
 */
export async function createGraphicHandler({
  event,
  user,
  sendRequest,
  notification,
  canvasContext,
  setCanvasObjectList,
  levelBased,
  setFileToUpload,
  setImageUrl,
  setAddedImages,
  setFetchGraphics,
}: CreateGraphicArgs): Promise<void> {
  try {
    if (!event?.target?.files?.[0]) {
      console.error("No file selected.");
      return;
    }

    const file = event.target.files[0];

    // Optional: store the file in state if needed
    if (setFileToUpload) {
      setFileToUpload(file);
    }

    // If no user, skip server upload -> read file as base64
    if (!user) {
      handleLocalFile(file);
      return;
    }

    // 1) Upload the file to the server
    const draft_id = generateRandomHexString(32);
    const fileData = { file, draft_id, name: file.name };
    const serverResponse = await sendFileHandler(
      fileData,
      sendRequest,
      notification,
      setImageUrl,
      setAddedImages,
      setFetchGraphics
    );

    if (!serverResponse?.url) {
      return;
    }

    // 2) Read the file locally to get dimension data
    const reader = new FileReader();
    reader.onload = (readEvent: ProgressEvent<FileReader>) => {
      if (!readEvent.target?.result) {
        return;
      }
      const img = new Image();
      img.onload = async () => {
        const width = img.width;
        const height = img.height;

        // 3) Add the graphic to the canvas using the server URL
        const constructImageToAddInfo = {
          url: serverResponse.url, // Use server-returned URL
          name: file?.name || "new image",
          fileType: "img",
          width,
          height,
          level: levelBased,
        };

        const addedImage = await addGateway(canvasContext, constructImageToAddInfo, setCanvasObjectList);

        // 4) Optionally, calculate DPI compliance if needed
        const workingArea = canvasContext?.getObjects()?.find((obj: any) => obj.level === 6);

        if (workingArea && addedImage) {
          const { isDpiSufficient } = calculateDIP(addedImage, workingArea?.dpi || 300, notification);
          addedImage.set({ isDpiSufficient });
          addedImage.setCoords();
          canvasContext.renderAll();
        }
      };
      img.src = readEvent.target.result as string;
    };
    reader.readAsDataURL(file);
  } catch (error) {
    console.error("Error creating graphic:", error);
    notification?.({
      id: uniqueID(),
      type: "ERROR",
      message: "Something went wrong while creating the graphic.",
    });
  }

  /**
   * handleLocalFile
   *
   * Fallback method for when the user is NOT logged in.
   * Reads the file as base64 and adds directly to canvas (no server upload).
   */
  function handleLocalFile(localFile: File) {
    const reader = new FileReader();
    reader.onload = (readEvent) => {
      if (!readEvent.target?.result) return;

      const img = new Image();
      img.onload = async () => {
        const width = img.width;
        const height = img.height;
        const dpi = 72; // or your logic for DPI

        const constructImageToAddInfo = {
          url: readEvent.target?.result as string, // base64
          name: localFile?.name || "new image",
          fileType: "img",
          width,
          height,
          dpi,
          level: 4,
        };

        await addGateway(canvasContext, constructImageToAddInfo, setCanvasObjectList);
      };
      img.src = readEvent.target.result as string;
    };
    reader.readAsDataURL(localFile);
  }
}

/**
 * sendFileHandler
 *
 * Helper function to upload a file to your server and handle response.
 */
async function sendFileHandler(
  fileData: { file: File; draft_id: string; name: string },
  sendRequest: (method: string, url: string, data?: any) => Promise<any>,
  notification: (options: { id: string; type: string; message: string }) => void,
  setImageUrl?: React.Dispatch<React.SetStateAction<string>>,
  setAddedImages?: React.Dispatch<React.SetStateAction<any[]>>,
  setFetchGraphics?: React.Dispatch<React.SetStateAction<boolean>>
) {
  try {
    const formData = new FormData();
    formData.append("attachment", fileData.file);
    formData.append("draft_id", fileData.draft_id);

    const response = await sendRequest("post", "/upload-pending-attachment", formData);
    if (!response) {
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: "Server error: no response from upload",
      });
      return null;
    }

    // If success, handle state updates
    setImageUrl?.(response.url);
    setAddedImages?.((prevImages: any) => [
      ...prevImages,
      {
        url: response.url,
        name: fileData?.name || "new image",
        fileType: "img",
      },
    ]);

    notification({
      id: uniqueID(),
      type: "SUCCESS",
      message: "Graphic has been uploaded",
    });

    // Force re-fetch if needed
    setFetchGraphics?.((prev) => !prev);

    return response;
  } catch (err) {
    console.error("Error in sendFileHandler:", err);
    notification({
      id: uniqueID(),
      type: "ERROR",
      message: "Something went wrong during the file upload",
    });
    return null;
  }
}
