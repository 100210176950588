import React from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";

interface SlideUpModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const SlideUpModal: React.FC<SlideUpModalProps> = ({
  open,
  onClose,
  children,
}) => {
  const { setStyleIsActive } = React.useContext(FabricJSContextNew);

  // Combine onClose and handleClose into a single function
  const handleCombinedClose = () => {
    onClose(); // Call the onClose prop function
    setStyleIsActive(null); // Reset the style
  };

  return (
    <Modal
      open={open}
      onClose={handleCombinedClose} // Use the combined close function
      closeAfterTransition
      aria-labelledby="slide-up-modal"
      aria-describedby="slide-up-modal-description"
      style={{
        outline: "none",
        border: "none",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
            }}
          >
            {children}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default SlideUpModal;
