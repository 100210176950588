import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Payments from "../../Subscribtions/Payments/Payments";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import s from "./Payment.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface IPayment {
  onboard: boolean;
}

const Payment = ({ onboard }: IPayment) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paymentDone, setPaymentDone] = useState<boolean>(false);

  const handleNext = () => {
    navigate(onboard ? "/onboard/profile-setup" : "/subscription/managment");
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <div className={s.header}>
            <h2>VIDELINK</h2>
            <h3>Business</h3>
          </div>
          {!paymentDone ? (
            <Payments
              handleAfterPayment={() => setPaymentDone(true)}
              onboard={onboard}
            />
          ) : (
            <div className={s.payment_success_container}>
              <div className={s.success_box}>
                {t("PaymentComponent.Payment completed")}
              </div>
              <Button text={t("Next")} type="button" handleClick={handleNext} />
            </div>
          )}
          {/* <Button
          text={t("Next")}
          type="button"
          handleClick={handlePayment}
          //disabled={!isFormValid}
          //isLoading={isLoading}
        /> */}
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default Payment;
