import React, { useContext, useState } from "react";
import s from "./Subscriptions.module.scss";
import { useNavigate } from "react-router-dom";
import MiniNav from "../../components/Nav/MiniNav/MiniNav";
import RenderActionComponent from "../../components/RenderActionComponent/RenderActionComponent";
import Managment from "./Managment/Managment";
import History from "./History/History";
import Payments from "./Payments/Payments";
import { TNavigationItem } from "./types";
import { useTranslation } from "react-i18next";
import AuthContext from "../../core/context/AuthContext";

const actions: Record<string, JSX.Element> = {
  managment: <Managment />,
  history: <History />,
  payment: <Payments onboard={false} />,
};

const Subscriptions = () => {
  const { t } = useTranslation();
  const navigationMenu: TNavigationItem[] = [
    {
      name: t("Subscription"),
      link: "managment",
      ava: "settings",
      premium: false,
    },
    {
      name: t("SubscritpionNavigation.one"),
      link: "history",
      ava: "history",
      premium: false,
    },
    {
      name: t("SubscritpionNavigation.two"),
      link: "payment",
      ava: "payment",
      premium: false,
    },
  ];
  const [actionState, setActionState] = useState<any>(navigationMenu[0]);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const setAction = (newAction: any) => {
    if (user?.subscription?.plan?.level === 0 && newAction.premium) {
      return;
    }
    console.log(newAction, "newAction");
    setActionState(newAction);
    navigate(`/settings/${newAction.link}`);
  };

  return (
    <div className={s.container}>
      <MiniNav
        navigation={navigationMenu}
        action={actionState}
        setAction={setAction}
      />
      <div className={s.innerContainer}>
        <RenderActionComponent actions={actions} action={actionState.link} />
      </div>
    </div>
  );
};

export default Subscriptions;
