import React, { useContext, useEffect } from "react";
import WorkShopProject from "./components/WorkShopProject/WorkShopProject";
import s from "./FabricJS.module.scss";
import TopTools from "./components/TopTools/TopTools.wrapper";
import FabricJSContextNew from "../../../../core/context/FabricJSContextNew";
import { fabric } from "fabric";
// import "fabric-history"; // <-- This must come before creating new fabric.Canvas!

import WorkShopProduct from "./components/WorkShopProduct/WorkShopProduct";
import RightPanel from "./panels/RightPanel";

import { filterViewToShow } from "../../../../components/ProductComponent/filterViewToShow";
import { filterVersionToShow } from "../../../../helper/filterVersionToShow";

import { useLocation } from "react-router-dom";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import setupCanvasEventListeners from "../../fabric-functions/EventListiners/EventListiners";
import sortCanvasObjectsByLevel from "../../fabric-functions/SortCanvasObjectsByLevel";
import handleLoadCanvasProject, { centerCanvasAtOrigin } from "../../fabric-functions/LoadProject";
import { updateCanvasObjectsList } from "../../fabric-functions/UpdateCanvasObjectList";
import addGateway, { IFileInformation, ITextInformation } from "../../fabric-functions/AddToCanvas/AddGateway";
import takeCanvasSnapshot from "../../fabric-functions/CreateSnapShotImage";
import { useNotification } from "../../../../core/context/notifications/NotificationProvider";
import AddPanel from "./panels/AddPanel";
import AvailableViewsPanel from "./panels/AvailableViewsPanel";

const FabricJSNew = () => {
  const [isCanvasReady, setIsCanvasReady] = React.useState(false);
  const notification = useNotification();
  const {
    canvasContext,
    canvasRefs,
    canvasContainerRefs,
    setCanvasContext,
    setCanvasObjectList,
    setCanvasJSONContext,
    canvasJSONContext,
    activeGlobalState,
    setCanvasSize,
    canvasSize,
    setCanvasActiveObject,
    setActiveGlobalState,
    setCreatingNewWork,
    tempActiveGlobalState,
    creatingNewWork,
  } = useContext(FabricJSContextNew);
  const { sendRequest } = useApiRequest();

  useEffect(() => {
    if (canvasContainerRefs[0].current) {
      if (!canvasContext) {
        const newCanvas = new fabric.Canvas(canvasRefs[0].current);
        setCanvasContext(newCanvas);
        setCanvasJSONContext(newCanvas.toJSON());

        // Set the initial center of the canvas to (0,0)
        setIsCanvasReady(true); // Canvas is ready
      } else {
        if (canvasContainerRefs[0]) {
          canvasContainerRefs[0].current.innerHTML = "";
          canvasContainerRefs[0].current.appendChild(canvasContext.lowerCanvasEl);
          canvasContainerRefs[0].current.appendChild(canvasContext.upperCanvasEl);

          canvasContext.renderAll();
          setIsCanvasReady(true); // Canvas is ready
        }
      }
    }
  }, [canvasContext, setCanvasContext, canvasContainerRefs[0]]);

  useEffect(() => {
    if (canvasContext) {
      const cleanup = setupCanvasEventListeners(
        canvasContext,
        setCanvasContext,
        sortCanvasObjectsByLevel,
        setCanvasJSONContext,
        setCanvasObjectList,
        setActiveGlobalState,
        setCanvasActiveObject,
        notification
      );

      centerCanvasAtOrigin(canvasContext);
      updateCanvasObjectsList(canvasContext, setCanvasObjectList);
      // sortCanvasObjectsByLevel(canvasContext);

      return () => {
        cleanup();
      };
    }
  }, [canvasContext]);

  useEffect(() => {
    if (canvasContext) {
      canvasContext.setDimensions({
        width: canvasSize?.width - 1,
        height: canvasSize?.height - 1,
      });

      centerCanvasAtOrigin(canvasContext);
    }
  }, [canvasSize]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect) {
        setCanvasSize({
          width: entries[0].contentRect.width - 1,
          height: entries[0].contentRect.height - 1,
        });
      }
    });
    if (canvasContainerRefs[0].current) {
      resizeObserver.observe(canvasContainerRefs[0].current);
    }
    return () => {
      if (canvasContainerRefs[0].current) {
        resizeObserver.unobserve(canvasContainerRefs[0].current);
      }
    };
  }, [canvasContext, setCanvasSize]);

  const location = useLocation();
  const path = location.pathname;
  const requestToken = path.split("/")[3];
  const typePath = path.split("/")[2];

  const fetchDesignStudioData = async () => {
    const response: any = await sendRequest("get", `/creator/2d/${typePath}s?token=${requestToken}`);
    if (response) {
      const fileInfo = {
        level: 1,
        fileType: "img",
        url: creatingNewWork.newWorkImageUrl,
        name: "",
      };

      if (typePath === "template") {
        setActiveGlobalState((prevState: any) => ({
          ...prevState,
          fullJSON: response?.data,
          selectedView: response?.data?.views[0],
        }));

        const productToShow = filterViewToShow(response?.data?.views[0]?.uuid, response?.data, "templates");

        handleLoadCanvasProject(canvasContext, productToShow.canvasJSON, setCanvasObjectList);
        if (creatingNewWork.new) {
          isNewWorkCreated(fileInfo);
        }
      } else if (typePath === "project") {
        if (creatingNewWork.new) {
          const jsonToShow = filterViewToShow(
            activeGlobalState.selectedView.view_uuid,
            response.data,
            "templates",
            true //isNewProject
          );
          handleLoadCanvasProject(canvasContext, jsonToShow.canvasJSON, setCanvasObjectList);

          isNewWorkCreated(fileInfo);
        } else {
          const productToShow = filterVersionToShow(
            response?.data?.versions[0]?.view_uuid,
            response?.data?.versions[0]?.uuid,
            response?.data,
            true
          );
          setActiveGlobalState((prevState: any) => ({
            ...prevState,
            canvasType: "projects",
            fullJSON: response?.data,
            selectedView: response?.data?.versions[0],
          }));

          handleLoadCanvasProject(canvasContext, productToShow.canvasJSON, setCanvasObjectList);
        }
      }
    }
  };

  const isNewWorkCreated = (fileInfo: IFileInformation | ITextInformation) => {
    addGateway(canvasContext, fileInfo, setCanvasObjectList);
    setCreatingNewWork((prevState: any) => ({ ...prevState, new: false }));
  };

  useEffect(() => {
    if (location.pathname === "/design-studio" || location.pathname === "/design-studio/" || !isCanvasReady) return;

    // Ensure the canvas is fully ready before fetching data and updating the object list
    if (canvasContext && isCanvasReady) {
      console.log("running");
      fetchDesignStudioData();

      //run snapshot to create initial image when thigns are created,
      //use deley as there is an issue with the first image being uploaded to the server so we
      //have to wait for the canvas to be initilized
      setTimeout(() => {
        takeCanvasSnapshot(canvasContext, setActiveGlobalState);
      }, 1500);

      // Update the object list after ensuring the canvas is ready and objects are loaded
      updateCanvasObjectsList(canvasContext, setCanvasObjectList);
    }
  }, [isCanvasReady, location.pathname, canvasContext]);

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (canvasContext) {
        canvasContext.clear(); // Clear all objects from the canvas

        setCanvasContext(null); // Optionally reset the canvas context state
      }
    };
  }, [canvasContext, setCanvasContext]);

  return (
    <div style={{ width: "100%", display: "flex", height: "calc(100vh - 84px)" }}>
      <div
        style={{
          width: "99.5%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 80px)",
        }}
      >
        <TopTools />
        <div
          ref={canvasContainerRefs[0]}
          className={s.canvas_container}
          style={{
            width: "100%",
            display: "flex",
            height: "100%",
            position: "relative",
          }}
        >
          <canvas ref={canvasRefs[0]} id="main-fabric-working-studio" />
        </div>
      </div>
      <AvailableViewsPanel />
      <RightPanel />
      <AddPanel />
      {location.pathname.split("/")[2] === "template" ? (
        <WorkShopProduct />
      ) : location.pathname.split("/")[2] === "project" ? (
        <WorkShopProject />
      ) : null}
    </div>
  );
};

export default FabricJSNew;
