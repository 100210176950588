import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./Folder.module.scss";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { useApiRequest } from "../../../core/api/useApiRequest";
import NormalModal from "../../Modals/Modal/NormalModal";
import ModalWrapper from "../../Modals/Modal.wrapper";
import CreateFolder from "../../Modals/FolderModals/CreateFolder/CreateFolder";
import EditNameFolder from "../../Modals/FolderModals/EditNameFolder/EditNameFolder";
import { useTranslation } from "react-i18next";
import DeleteFolder from "../../Modals/FolderModals/DeleteFolder/DeleteFolder";
import AppContext from "../../../core/context/AppContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IFolder } from "../../../core/types/types";
import { Tooltip } from "@mui/material";
import SelectOptionWrapper from "../../SelectOption/SelectOptionWrapper";
interface IFolderComponent {
  handleClick: () => void;
  folder: IFolder;
  path: string;
  customeStyle?: React.CSSProperties;
  withTreeList?: boolean;
  isTreeVisible?: boolean;
  variant?: string;
}

const selectStyle: React.CSSProperties = {
  zIndex: "1300",
  width: "35px",

  position: "absolute",
};

const Folder = ({
  handleClick,
  folder,
  path,
  customeStyle,
  withTreeList,
  isTreeVisible,
  variant,
}: IFolderComponent) => {
  const [changeNameModal, setChangeNameModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const { t } = useTranslation();
  const { setCurrentFolder } = useContext(AppContext);

  const handleOpenChangeNameModal = () => setChangeNameModal(true);
  const handleOpenDeleteModal = () => setDeleteModal(true);

  const options = [
    [
      {
        function: handleOpenChangeNameModal,
        name: t("Change name"),
      },
      {
        function: handleOpenDeleteModal,
        name: t("Delete"),
      },
    ],
  ];

  const showOptionsHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    el: IFolder
  ) => {
    e.stopPropagation();
    setCurrentFolder(el);
  };

  return (
    <>
      <div
        className={`${s.container} ${
          variant === "2ex_small"
            ? s.two_ex_small
            : variant === "ex_small"
            ? s.ex_small
            : variant === "small"
            ? s.small
            : variant === "medium"
            ? s.medium
            : variant === "3ex_small"
            ? s.three_ex_small
            : s.large
        }`}
        onClick={() => handleClick()}
        style={customeStyle}
      >
        <div className={s.top}></div>
        <div className={s.bottom}>
          <Tooltip title={folder.name} placement="bottom">
            <span>{folder?.name}</span>
          </Tooltip>
          {!withTreeList ? (
            <div
              style={{ position: "relative" }}
              onClick={(e) => showOptionsHandler(e, folder)}
            >
              <SelectOptionWrapper
                options={options}
                openMore={showOptions}
                setOpenMore={setShowOptions}
                style={selectStyle}
                iconSize="medium"
              />
            </div>
          ) : (
            <KeyboardArrowDownIcon
              className={` ${s.arrow} ${isTreeVisible ? s.open : ""} `}
            />
          )}
        </div>
      </div>
      <NormalModal open={changeNameModal} setOpen={setChangeNameModal}>
        <ModalWrapper setOpen={setChangeNameModal}>
          <EditNameFolder setCloseModal={setChangeNameModal} />
        </ModalWrapper>
      </NormalModal>
      <NormalModal open={deleteModal} setOpen={setDeleteModal}>
        <ModalWrapper setOpen={setDeleteModal}>
          <DeleteFolder
            setCloseModal={setDeleteModal}
            path={path}
            folder={folder}
          />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default Folder;
