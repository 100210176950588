import React, { useEffect, useState } from "react";
import s from "./UserStore.module.scss";
import { useApiRequest } from "../../core/api/useApiRequest";
import { useLocation } from "react-router-dom";
import FilterComponent from "../../components/FilterComponent/FilterComponent";
import ProductComponent from "../../components/ProductComponent/ProductComponent";

const UserStore = () => {
  const { sendRequest } = useApiRequest();
  const location = useLocation();

  const [templates, setTemplates] = useState([]);
  const [folders, setFolders] = useState([]);

  const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);
  const [folderPath, setFolderPath] = useState<any[]>([]);

  const fetchStore = async () => {
    const response: any = await sendRequest("get", "/workspace");
    console.log(response, "store response");
  };

  const fetchVisibleFolders = async () => {
    const response: any = await sendRequest("get", "my-site/visible-categories");
    console.log(response, "folders response");
    setFolders(response?.data || []);
  };

  const fetchContent = async () => {
    const response: any = await sendRequest("get", "creator/2d/templates?category_token=&search_in_descendants=true");
    console.log(response, "content");
    setTemplates(response.data);
  };

  useEffect(() => {
    fetchStore();
    fetchVisibleFolders();
    fetchContent();
  }, []);

  useEffect(() => {
    if (folderPath.length > 0) {
      const lastFolder = folderPath[folderPath.length - 1];
      setCurrentFolderId(lastFolder.id);
    } else {
      setCurrentFolderId(null);
    }
  }, [folderPath]);

  const handleFolderClick = (folder: any) => {
    setFolderPath((prev) => [...prev, folder]);
  };

  const handleBackClick = () => {
    setFolderPath((prev) => prev.slice(0, -1));
  };

  const visibleFolders = folders.filter((folderItem: any) => folderItem.parent_id === (currentFolderId ?? 0));

  const handleClick = (selectedView: any, template: any) => {
    console.log("Template clicked => ", template, "with view => ", selectedView);
  };

  return (
    <div className={s.container}>
      <div className={s.one}>
        {folderPath.length > 0 && <button onClick={handleBackClick}>Back</button>}
        {visibleFolders.map((folder: any) => (
          <div key={folder.id} className={s.folderItem} onClick={() => handleFolderClick(folder)}>
            {folder.name}
          </div>
        ))}
      </div>

      <div className={s.two}>
        <FilterComponent
          endpoint="/creator/2d/templates"
          defaultSort="id"
          defaultPagination={20}
          defaultFilter={"is_draft:false"}
          isPagination={true}
          category_id={currentFolderId?.toString()}
          render={({ data }) => (
            <div className={s.map_container}>
              {data.map((el: any, i: number) => (
                <ProductComponent
                  key={`template-map-${i}`}
                  product={el}
                  variant="ex_small"
                  handleClick={(variant) => handleClick(variant, el)}
                  id={el?.id}
                  componentOptions="1"
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default UserStore;
